import React from 'react';
import { useToken } from './token';
import { publish } from 'pubsub-js';

const CoreContext = React.createContext(null);
const server = '/dart/core';
let actionQueue = [];

function CoreProvider({children}) {
    const { token, updateToken } = useToken();

    const runNoAuthAction = (action, params, handler) => {
        let data = Object.assign({}, params);
        data.action = action;
        fetch(server, {
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(data)
        }).then(res => res.json())
        .then(
            (result) => {
                if (handler) {
                    if (result.hasOwnProperty('success')) {
                        if (result.success) {
                            handler(result, null);
                        } else {
                            handler(null, result.errorMessage);
                        }
                    } else {
                        handler(result, null);
                    }
                }
            },
            (error) => {
                // TODO: if 401 - logout
                if (handler) {
                    handler(null, error);
                }
            }
        );
    }

    const runAction = async (action, params, handler) => {
        if (token) {
            let data = Object.assign({}, params);
            data.action = action;
            if (actionQueue.length > 0 && actionQueue.find(a => a.action === action) !== undefined) {
                return;
            } else {
                actionQueue.push({action, params, handler});
            }
            try {
                await fetch(server, {
                    method: "POST",
                    headers: {'Content-Type':'application/json', 'X-Dart-Token': token},
                    body: JSON.stringify(data)
                })
                .then((response) => {
                    if (action === 'authorize' && params.newToken) {
                        const token = response.headers.get('X-Dart-Token');
                        updateToken(token);
                    }
                    if (response.status === 401) {
                        publish('warning', 'You dont have permission to access this content.');
                        return {error: 401};
                    }
                    if (response.status === 403) {
                        publish('error', 'You do not have permission to perform this action.');
                        return {error: 403};
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                        if (handler && !result.error) {
                            if (result.hasOwnProperty('success')) {
                                if (result.success) {
                                    handler(result);
                                } else {
                                    publish('error', 'An error has occurred: ' + result.errorMessage);
                                    handler(result);
                                }
                            } else {
                                handler(result);
                            }
                        }
                    }
                )
                .catch(error => {
                    console.error(error);
                    publish('error', 'A server error has occurred, please try again or contact customer support.');
                });
            } catch (error) {
                console.log(error);
            } finally {
                const actionIndex = actionQueue.findIndex(a => a.action == action);
                actionQueue.splice(actionIndex, 1);
            }
        } else if (handler) {
            publish('error', 'Your session appears to be expired, please log in.');
            handler(null);
        }
    }

    const runCSVAction = async (action, params, handler) => {
        if (token) {
            let data = Object.assign({}, params);
            data.action = action;
            if (actionQueue.length > 0 && actionQueue.find(a => a.action === action) !== undefined) {
                return;
            } else {
                actionQueue.push({action, params, handler});
            }
            try {
                await fetch(server, {
                    method: "POST",
                    headers: {'Content-Type':'application/json', 'X-Dart-Token': token},
                    body: JSON.stringify(data)
                })
                .then((response) => {
                    return response.text();
                })
                .then(
                    (result) => {
                        if (handler) {
                            handler(result);    
                        }
                    }
                )
                .catch(error => {
                    console.error(error.message);
                    publish('error', 'A server error has occurred, please try again or contact customer support.');
                });
            } catch (error) {
                console.log(error);
            } finally {
                const actionIndex = actionQueue.findIndex(a => a.action == action);
                actionQueue.splice(actionIndex, 1);
            }
        } else if (handler) {
            publish('error', 'Your session appears to be expired, please log in.');
            handler(null);
        }
    }

    const provider = {
        runNoAuthAction,
        runAction,
        runCSVAction,
    }

    return <CoreContext.Provider value={provider}>{children}</CoreContext.Provider>
}

function useCore() {
    const context = React.useContext(CoreContext);
    if (!context) {
        throw new Error('useCore must be used within a CoreProvider');
    }
    return context;
}

export { CoreProvider, useCore }