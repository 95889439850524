import React, { useState, useRef } from 'react';
import AmazingTable from 'components/amazingTable';
import Inventory from './inventory';
import SidePanel from 'components/sidePanel';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useInventory } from 'contexts/inventory';

const inventoryColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "name", label:"Name", display: true, datum: "name"},
    {id: "quantity", label:"Quantity", display: true, datum: "quantity"},
    {id: "price", label:"Price", display: true, datum: "price"},
    {id: "shipping", label:"Shipping Cost", display: true, datum: "shipping"},
    {id: "discount", label:"Discount", display: true, datum: "discount"},
    {id: "available", label:"Availability Date", display: true, datum: "available"}
];

function ProductInventory() {
    const { inventory, loadInventory, createInventory, updateInventory, deleteInventories } = useInventory();
    const [item, setItem] = useState(null);
    const inventoryPanel = useRef(null);

    const showInventory = (item) => {
        setItem(item);
        inventoryPanel.current.show();
    }

    const addInventory = () => {
        let item = {name: '', quantity: 0, price: 0, shipping: 0, discount: 0}
        setItem(item);
        inventoryPanel.current.show();
    }

    const hideInventory = () => {
        setItem(null);
    }

    const save = (data) => {
        if ('id' in data) {
            updateInventory(data, () => {
                inventoryPanel.current.hide();
            })
        } else {
            createInventory(data, () => {
                inventoryPanel.current.hide();
            })
        }
    }

    const deleteInventory = (items) => {
        deleteInventories(items[0].id);
    }

    return (
        <div>
            <TableSetupProvider tableName='inventory' defaultColumns={inventoryColumns}>
                <AmazingTable 
                    rows={inventory}
                    onRefresh={loadInventory}
                    onRowClick={showInventory}
                    onCreate={addInventory}
                    onCreateLabel='Add inventory'
                    onDelete={deleteInventory}
                />
            </TableSetupProvider>
            <SidePanel title='Inventory' ref={inventoryPanel} onHide={hideInventory}>
                {item && <Inventory item={item} onSave={save} /> }
            </SidePanel>
        </div>
    )
}

export default ProductInventory;