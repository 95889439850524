import React, { useEffect, useState } from 'react';
import Loading from 'components/loading';
import { useLoaderData } from 'react-router-dom';
import { ClassesProvider } from 'contexts/classes';
import { useCore } from 'contexts/core';
import { EventDashcardProvider } from 'contexts/eventDashcard';
import Paper from './paper';
import DefaultDashCard from './defaultDashCard';

export async function loader({params}) {
    return params;
}

function DashCard({}) {
    const loaderData = useLoaderData();
    const { runNoAuthAction } = useCore();
    const [loading, setLoading] = useState(true);
    const [entryData, setEntryData] = useState({});

    useEffect(() => {
        let data = {tenantUUID: loaderData.tenant, event: loaderData.event, car_number: loaderData.car};
        runNoAuthAction('get_entry', data, (response) => {
            setEntryData(response);
            setLoading(false);
        })
    }, []);

    return (
        <div className='dashcard-container'>
            {loading 
                ? <Loading /> 
                : (loaderData.dashCardId == 0 ? 
                    <DefaultDashCard tenantId={loaderData.tenant} eventId={loaderData.event} entryData={entryData} />
                    : <EventDashcardProvider eventId={loaderData.event}>
                    <ClassesProvider eventId={loaderData.event}>
                        <Paper 
                            dashcardId={loaderData.dashCardId} 
                            entryData={entryData} 
                            tenantId={loaderData.tenant}
                            eventId={loaderData.event} />
                    </ClassesProvider>
                </EventDashcardProvider>
            )}
        </div>
    )
}

export default DashCard;
