import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const EmailTemplatesContext = React.createContext(null);

function EmailTemplatesProvider({children}) {
    const { runAction } = useCore();
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        loadTemplates();
    }, [])

    const loadTemplates = async () => {
        setLoadingTemplates(true);
        await runAction('get_email_templates', {}, (response) => {
            setTemplates(response.templates);
            setLoadingTemplates(false);
        });
    }

    const createTemplate = async (data, callback) => {
        await runAction('create_email_template', data, (result, error) => {
            if (result) {
                callback();
                loadTemplates();
            } else {
                throw new Error(error);
            }
        });
    }

    const copyTemplate = async (templateId) => {
        await runAction('copy_email_template', {id: templateId}, response => {
            loadTemplates();
        })
    }

    const saveTemplate = async (data, callback) => {
        await runAction('update_email_template', data, (result, error) => {
            if (result) {
                callback();
                loadTemplates();
            } else {
                throw new Error(error);
            }
        });
    }

    const deleteTemplates = (ids) => {
        runAction('delete_email_template', { ids }, (result, error) => {
            if (result) {
                loadTemplates();
            } else {
                throw new Error(error);
            }
        });
    }

    const sendTemplate = (data) => {
        runAction('send_email_template', data, (result) => {
            if (result) {
                publish('success', 'An email has been sent.');
            }
        });
    }

    const provider = {
        loadingTemplates,
        templates,
        loadTemplates,
        createTemplate,
        copyTemplate,
        saveTemplate,
        deleteTemplates,
        sendTemplate,
    }

    return <EmailTemplatesContext.Provider value={provider}>{children}</EmailTemplatesContext.Provider>
}

function useEmailTemplates() {
    const context = React.useContext(EmailTemplatesContext);
    if (!context) {
        throw Error('useEmailTemplates must be used with a EmailTemplatesProvider');
    }
    return context;
}

export { EmailTemplatesProvider, useEmailTemplates }