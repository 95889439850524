import Toggle from 'components/toggle';
import { useClasses } from 'contexts/classes';
import React, { useEffect, useState } from 'react';
import { publish } from 'pubsub-js';

function ClassDetails({carClass, onSave}) {
    const { classes, saveClass } = useClasses();
    const [carClassNumber, setCarClassNumber] = useState(carClass ? carClass.id : '0');
    const [section, setSection] = useState(carClass ? carClass.section : '0');
    const [description, setDescription] = useState(carClass ? carClass.description : '');
    const [hidden, setHidden] = useState(carClass ? carClass.hidden : false);

    useEffect(() => {
        if (carClass) {
            setCarClassNumber(carClass.id);
            setSection(carClass.section);
            setDescription(carClass.description);
            setHidden(carClass.hidden);
        } else {
            setCarClassNumber('0');
            setSection('0');
            setDescription('');
            setHidden(false);
        }
    }, [carClass])

    const saveClassHandler = () => {
        if (carClassNumber === '0') {
            publish('error', 'Class number is required');
            return;
        }
        if (section === '0') {
            publish('error', 'Section/Location/Area/Lot/Building is required');
            return;
        }
        if (description.length === 0) {
            publish('error', 'A class description is required');
            return;
        }
        let data = {id: carClassNumber, section, description, hidden};
        if (!carClass) {
            data.isNew = true;
        }
        saveClass(data, () => {
            onSave();
        });
    }

    const generateClassNumbers = () => {
        const options = [];
        for (let i = 100; i <= 10000; i += 100) {
            const foundClass = classes.findIndex(c => c.id === i);
            const isDisabled = foundClass > -1 && (carClass ? carClass.id !== i : true);
            options.push(<option key={i} value={i} disabled={isDisabled}>{i}</option>);
        }
        return options;
    };

    return (
        <div>
            <div className="contact-preview">
                <label>Class number</label>
                <select value={carClassNumber} onChange={e => setCarClassNumber(e.target.value)}>
                    <option value='0'>-- Chose One --</option>
                    {generateClassNumbers()}
                </select>
                <label>Section/Location/Area/Lot/Building</label>
                <select value={section} onChange={e => setSection(e.target.value)}>
                    <option value='0'>-- Choose One --</option>
                    {['A','B','C','D','E','F','G','H','I','J','K','L','M',
                        'N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
                        .map(ltr => <option key={ltr} value={ltr}>{ltr}</option>)}
                    {['AA','BB','CC','DD','EE','FF','GG','HH','II','JJ','KK',
                        'LL','MM','NN','OO','PP','QQ','RR','SS','TT','UU','VV',
                        'WW','XX','YY','ZZ'].map(ltr => <option key={ltr} value={ltr}>{ltr}</option>)}
                    
                </select>
                <label>Description</label>
                <input type="text" value={description} onChange={e => setDescription(e.target.value)} maxLength="64" />
                <label>Hidden from online pre-registration</label>
                <Toggle checked={hidden} onChange={newValue => setHidden(newValue)} />
            </div>
            <div className="buttonsHolder">
                <button onClick={saveClassHandler}>Save</button>
            </div>
        </div>
    )
}

export default ClassDetails;