import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Toggle from 'components/toggle';
import moment from 'moment';
import { useEvents } from 'contexts/events';
import { useEmailTemplates } from 'contexts/emailTemplates';
import { PillBoxProvider } from 'contexts/tagPillbox';
import PillBox from 'components/tagPillbox';
import { TagsProvider } from 'contexts/tags';
import { useFlow } from 'contexts/flow';
import { useSettings } from 'contexts/settings';
import { EventTicketsProvider, useEventTickets } from 'contexts/eventTickets';

const audiences = [
    { name: 'All', value: 'all' },
    { name: 'Active', value: 'active' },
    { name: 'Expired', value: 'expired' },
    { name: 'Expiring Soon', value: 'expiring' },
    { name: 'Newsletter', value: 'newsletter' },
    { name: 'Tagged', value: 'tagged' },
    { name: 'Event Participants', value: 'eventParticipants'},
    { name: 'Event Vendors', value: 'eventVendors'},
    { name: 'Event Tickets', value: 'eventTickets'},
    { name: 'Event Workers', value: 'eventWorkers'},
];

function CampaignDetails({campaign, initialName='', onSave}) {
    const { templates } = useEmailTemplates();
    const { flows } = useFlow();
    const { tenant } = useSettings();
    const [name, setName] = useState(initialName);
    const [status, setStatus] = useState('Queued');
    const [auditEmail, setAuditEmail] = useState('');
    const [active, setActive] = useState(true);
    const [audience, setAudience] = useState('all');
    const [tags, setTags] = useState([]);
    const [eventId, setEventId] = useState(0);
    const [eventTicketId, setEventTicketId] = useState(0);
    const [deliveryDate, setDeliveryDate] = useState(moment().add(1, 'w').valueOf());
    const [email, setEmail] = useState(0);
    const [flow, setFlow] = useState(false);
    const [templateOrFlowId, setTemplateOrFlowId] = useState(0);
    const { events } = useEvents();

    useEffect(() => {
        if (tenant && tenant.auditEmail) {
            setAuditEmail(tenant.auditEmail);
        }
    }, [tenant])

    useEffect(() => {
        if (initialName) {
            setName(initialName);
        }
    }, [initialName])

    useEffect(() => {
        if (campaign) {
            setName(campaign.name);
            setStatus(campaign.status);
            setAuditEmail(campaign.auditEmail);
            setActive(campaign.active);
            setAudience(campaign.audience);
            setTags(campaign.tags);
            setEventId(campaign.eventId);
            setEventTicketId(0); // this is set in eventTicketsLoadedHandler
            setDeliveryDate(campaign.deliveryDate);
            setEmail(campaign.email);
            setFlow(campaign.flow);
            setTemplateOrFlowId((campaign.flow ? 'flow:' : '') + campaign.email);
        } else {
            setName(initialName);
            setStatus('Queued');
            setAuditEmail('');
            setActive(true);
            setAudience('active');
            setTags([]);
            setEventId(0);
            setEventTicketId(0)
            setDeliveryDate(moment().add(1, 'd').valueOf());
            setEmail(0);
            setFlow(false);
            setTemplateOrFlowId(0);
        }
    }, [campaign]);

    const saveCampaign = () => {
        let data = {
            name: name,
            auditEmail: auditEmail,
            active: active,
            audience: audience,
            tags: tags,
            eventId: eventId,
            eventTicketId,
            deliveryDate: deliveryDate,
            email: email,
            flow,
        };
        if (campaign) {
            data.id = campaign.id;
        }
        if (onSave) {
            onSave(data);
        }
    };

    const changeTemplateOrFlowHandler = (evt) => {
        const selectedValue = evt.target.value;
        setTemplateOrFlowId(selectedValue);
        if (selectedValue.startsWith('flow:')) {
            setFlow(true);
            setEmail(selectedValue.substring(5));
        } else {
            setFlow(false);
            setEmail(selectedValue);
        }
    }

    const setAudienceHandler = (evt) => {
        setAudience(evt.target.value);
    }

    const eventTicketsLoadedHandler = () => {
        if (campaign) {
            setEventTicketId(campaign.eventTicketId);
        }
    }

    return (
        <div className='settings-panel'>
            <h4>Basic Info</h4>
            <hr/>
            <h5>Campaign name</h5>
            <input type='text' value={name} onChange={(evt) => {setName(evt.target.value)}} maxLength={64} style={{width:'350px'}}/>
            <h5>Email template or flow</h5>
            <select value={templateOrFlowId} onChange={changeTemplateOrFlowHandler}>
                <option value='0'>None</option>
                <optgroup label='Template'>
                {templates.map(email => 
                    <option key={email.id} value={email.id}>{email.name}</option>
                )}
                </optgroup>
                {<optgroup label='Flow'>
                    {flows.map(flow => <option key={flow.id} value={`flow:${flow.id}`}>{flow.name}</option>)}
                </optgroup>}
            </select>
            <h5>Active</h5>
            <Toggle checked={active} onChange={() => setActive(!active)} />
            <h5>Send audit email to</h5>
            <input type='text' value={auditEmail} onChange={(evt) => {setAuditEmail(evt.target.value)}} maxLength={64} style={{width:'350px'}} />
            <div className='spacer'/>
            <h4>Send to</h4>
            <hr/>
            <h5>Audience</h5>
            <select value={audience} onChange={setAudienceHandler}>
                {audiences.map((aud, idx) => (
                    <option key={idx} value={aud.value}>{aud.name}</option>
                ))}
            </select>
            {audience === 'tagged' &&
                <>
                <h5>Tags</h5>
                <TagsProvider><PillBoxProvider tags={tags} onChange={setTags} ><PillBox /></PillBoxProvider></TagsProvider>
                </>
            }
            {audience.startsWith('event') &&
                <>
                <h5>Event</h5>
                <select value={eventId} onChange={e => setEventId(e.target.value)} disabled={!audience.startsWith('event')}>
                    <option value='0'>-- Choose One --</option>
                    {events.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
                </select>
                </>
            }
            {audience === 'eventTickets' && eventId !== 0 &&
                <>
                <h5>Ticket</h5>
                <select value={eventTicketId} onChange={e => setEventTicketId(e.target.value)}>
                    <option value='0'>All</option>
                    <EventTicketsProvider eventId={eventId}>
                        <EventTicketOptions onLoaded={eventTicketsLoadedHandler}/>
                    </EventTicketsProvider>
                </select>
                </>
            }
            <div className='spacer'/>
            <h4>Delivery</h4>
            <hr/>
            <h5>Delivery date</h5>
            <div>
                <DatePicker selected={deliveryDate} onChange={(date) => setDeliveryDate(Date.parse(date))} disabled={flow} />
            </div>
            {campaign &&
                <>
                    <h5>Status</h5>
                    <div style={{ paddingLeft: '5px' }}>{status}</div>
                </>
            }
            <br />
            <div className='buttonsHolder'>
                <button 
                    onClick={saveCampaign} 
                    disabled={(status === 'Processed') || name.length === 0 || templateOrFlowId === 0} >
                        Save
                </button>
            </div>
        </div>
    );
}

function EventTicketOptions({onLoaded}) {
    const { loadingEventTickets, eventTickets } = useEventTickets();

    useEffect(() => {
        if (!loadingEventTickets) {
            onLoaded();
        }
    }, [loadingEventTickets])

    return (
        <>
            {eventTickets.map(et => <option key={et.id} value={et.id}>{et.description}</option>)}
        </>
    )
}

export default CampaignDetails;
