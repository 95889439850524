import AmazingTable from 'components/amazingTable';
import Modal from 'components/modal';
import { useEventRegistrationOptions } from 'contexts/eventRegistrationOptions';
import { TableSetupProvider } from 'contexts/tableSetup';
import React, { useState } from 'react';
import Option from './option';

function Options() {
    const {loadingOptions,
        options,
        loadOptions,
        createOption,
        updateOption,
        deleteOptions} = useEventRegistrationOptions();
    const [showOption, setShowOption] = useState(false);
    const [option, setOption] = useState(null);

    const columns = [
        {id: 'id', label: 'ID', visible: false, datum: 'id'},
        {id: 'eventId', label:'Event ID', display: false, datum: 'eventId'},
        {id: 'type', label: 'Type', style:{width:'70px'}, display: true, formatter: obj => {return obj.type === 1 ? 'Text' : 'Choice'}},
        {id: 'label', label: 'Label', style:{width:'100px'}, display: true, datum: 'label'},
        {id: 'sublabel', label: 'Sub-Label', style:{width:'100px'}, display: true, datum: 'subLabel'},
        {id: 'required', label: 'Req', style:{width:'50px'}, display: true, formatter: obj => {return obj.required ? 'Yes' : 'No'} },
    ]

    const editHandler = (opt) => {
        setOption(opt);
        setShowOption(true);
    }
    const onCreateHandler = () => {
        setOption(null);
        setShowOption(true);
    }
    const removeOptionsHandler = (rows) => {
        const ids = rows.map(row => row.id);
        deleteOptions(ids);
    }
    const saveOptionHandler = (opt) => {
        setShowOption(false);
        if (option) {
            // update
            const data = {...option, ...opt}
            updateOption(data);
        } else {
            // create
            createOption(opt);
        }
    }

    return (
        <div>
            <TableSetupProvider tableName='registrationoptions' defaultColumns={columns}>
                <AmazingTable 
                    rows={options}
                    loading={loadingOptions}
                    onRefresh={loadOptions}
                    onRowClick={editHandler}
                    onCreate={onCreateHandler}
                    onCreateLabel='Add option'
                    onDelete={removeOptionsHandler}
                />
            </TableSetupProvider>
            <Modal title='Registration option' showing={showOption} onHide={() => setShowOption(false)}>
                <Option option={option} onSave={saveOptionHandler}/>
            </Modal>
        </div>
    )
}

export default Options;