import React, { useState } from 'react';
import AmazingTable from 'components/amazingTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faIdCard} from '@fortawesome/pro-regular-svg-icons';
import RegistrationDetails from './registrationDetails';
import SidePanel from 'components/sidePanel';
import { useAuth } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import { useClasses } from 'contexts/classes';
import { useRegistrations } from 'contexts/registrations';
import { PaginationProvider } from 'contexts/pagination';
import IconButton from 'components/iconButton';
import { TableSetupProvider } from 'contexts/tableSetup';
import Modal from 'components/modal';
import { useEventDashcard } from 'contexts/eventDashcard';
import { EventRegistrationOptionsProvider } from 'contexts/eventRegistrationOptions';

const sorters = [
    {id: "sort_car", label: "Car Number", order: "car_number", isDefault: true},
    {id: "sort_lname", label: "Last Name", order: "lname"}, 
    {id: "sort_fname", label: "First Name", order: "fname"}
];

function Registrations({event}) {
    const navigate = useNavigate();
    const { classes } = useClasses();
    const { entriesLoading, entries, limit, offset, fullCount, processedTotal, getRegistrations, saveRegistration, deleteRegistrations } = useRegistrations();
    const { dashcards } = useEventDashcard();
    const [registration, setRegistration] = useState(null);
    const [showingDetails, setShowingDetails] = useState(false);
    const [showRegcardDialog, setShowRegcardDialog] = useState(false);
    const [cardId, setCardId] = useState('0');
    const regDetailsPanel = React.createRef();
    const auth = useAuth();

    const regColumns = [
        {id: 'name', label: 'Name', display: true, style:{width:'100px'}, formatter: (obj) => {return obj.fname + ' ' + obj.lname;}},
        {id: 'fname', label: 'First Name', display: false, style:{width:'100px'}, datum: 'fname'},
        {id: 'lname', label: 'Last Name', display: false, style:{width:'100px'}, datum: 'lname'},
        {id: 'address1', label: 'Address 1', display: false, style:{width:'100px'}, datum: 'address1'},
        {id: 'address2', label: 'Address 2', display: false, style:{width:'100px'}, datum: 'address2'},
        {id: 'city', label: 'City', display: false, style:{width:'100px'}, datum: 'city'},
        {id: 'state', label: 'State', display: false, style:{width:'100px'}, datum: 'state'},
        {id: 'zip', label: 'Postal Code', display: false, style:{width:'100px'}, datum: 'zip'},
        {id: 'country', label: 'Country', display: false, style:{width:'100px'}, datum: 'country'},
        {id: 'phone', label: 'Phone', display: false, style:{width:'100px'}, datum: 'phone'},
        {id: 'email', label: 'Email', display: false, style:{width:'100px'}, datum: 'email'},
        {id: 'year', label: 'Year', display: true, style:{width:'50px'}, datum: 'year'},
        {id: 'make', label: 'Make', display: true, style:{width:'100px'}, datum: 'make'},
        {id: 'model', label: 'Model', display: true, style:{width:'100px'}, datum: 'model'},
        {id: 'trim', label: 'Trim', display: true, style:{width:'100px'}, datum: 'trim'},
        {id: 'color', label: 'Color', display: true, style:{width:'100px'}, datum: 'color'},
        {id: 'car_number', label: 'Number', display: true, style:{width:'100px'}, datum: 'car_number'},
        {id: 'car_class', label: 'Class', display: false, style:{width:'100px'}, datum: 'car_class'},
        {id: 'processed', label: 'Processed', display: true, style:{width:'100px'}, formatter: (obj) => {
            return <FontAwesomeIcon icon={obj.processed ? faThumbsUp : faThumbsDown}/>}},
        {id: 'dashcard', label: 'Dash Card', display: true, builder: (obj) => {
            return <IconButton icon={faIdCard} onClick={(evt) => dashCard(evt, obj)} label='Dash card'/>
        }},
        {id: 'values', label: 'Options', display: true, formatter: obj => obj.values?.map(item => item.value).join(', ')}
    ];

    const getRegistrationsHandler = (search) => {
        setRegistration(null);
        getRegistrations(search);
    }

    const onNextPage = (search) => {
        if (offset + limit < fullCount) {
            getRegistrations({...search, offset: offset + limit, limit});
        }
    }

    const onPreviousPage = (search) => {
        if (offset !== 0) {
            getRegistrations({...search, offset: offset - limit, limit});
        }
    }
    
    const dashCard = (evt, reg) => {
        evt.preventDefault();
        evt.stopPropagation();
        setRegistration(reg);
        setShowRegcardDialog(true);
    }

    const createRegistrationCardHandler = () => {
        
        const path = `/dashcard/${cardId}/${auth.tenant.uuid}/${event.id}/${registration.car_number}`;
        window.open(path, '_blank', 'noopener noreferrer');
        
        setShowRegcardDialog(false);
        setRegistration(null);
    }

    const createHandler = () => {
        setRegistration(null);
        regDetailsPanel.current.show();
        setShowingDetails(true);
    }

    const rowClickHandler = (row) => {
        // set the registraiton to the row
        setRegistration(row);
        regDetailsPanel.current.show();
        setShowingDetails(true);
    }

    const hideDetailsPanel = () => {
        setRegistration(null);
        setShowingDetails(false);
    }

    const saveRegistrationHandler = (data) => {
        regDetailsPanel.current.hide();
        setShowingDetails(false);
        if (registration) {
            saveRegistration(data, false);
        } else {
            saveRegistration(data, true);
        }
    }

    const labelHandler = () => {
        navigate(`/labels/vendors/${auth.tenant.uuid}/${event.id}`);
    }

    const exportHandler = (filter, cols, sorter) => {
        window.open("/dart/core?action=get_all&format=csv&columns=" + cols + "&orderby=" + sorter + "&event=" + event.id + "&tenantUUID=" + auth.tenant.uuid, '_blank', 'noopener noreferrer');
    }

    const deleteHandler = (rows) => {
        const ids = rows.map(row => row.car_number);
        deleteRegistrations(ids);
        regDetailsPanel.current.hide();
    }

    return (
        <div>
            <div style={{margin:'10px auto',border:'1px solid gray',borderRadius:'5px',padding:'20px',width:'200px',textAlign:'center'}}>{`${fullCount} Total / ${processedTotal} Processed`}</div>
            <TableSetupProvider tableName='registration' defaultColumns={regColumns}>
                <PaginationProvider 
                    currentPage={(offset + limit) / limit} 
                    maxPages={Math.ceil(fullCount/limit)}
                    nextPageHandler={onNextPage}
                    previousPageHandler={onPreviousPage}>
                    <AmazingTable 
                        hasPagination={true}
                        hasSearch={true}
                        sorters={sorters}
                        rows={entries}
                        onRefresh={getRegistrationsHandler}
                        onTableChange={getRegistrationsHandler}
                        onCreate={createHandler}
                        onCreateLabel='Add registration'
                        onRowClick={rowClickHandler}
                        onLabel={labelHandler}
                        onExport={exportHandler}
                        onDelete={deleteHandler}
                        loading={entriesLoading}
                    />
                </PaginationProvider>
            </TableSetupProvider>
            <SidePanel title='Registration' ref={regDetailsPanel} onHide={hideDetailsPanel}>
                {showingDetails && 
                    <EventRegistrationOptionsProvider eventId={event.id}>
                        <RegistrationDetails 
                            event={event}
                            classes={classes} 
                            registration={registration} 
                            onSave={saveRegistrationHandler}
                        />
                    </EventRegistrationOptionsProvider>
                }
            </SidePanel>
            <Modal 
                title='Registration card' 
                showing={showRegcardDialog} 
                onHide={() => {setShowRegcardDialog(false); setRegistration(null);}}
                buttons={[
                    {   type: 'primary', 
                        handler: () => {createRegistrationCardHandler()},
                        label:'Create registration card'
                    }
                ]}
                >
                <p style={{width:'300px'}}>
                    Select the dashcard to use. If you don't have any
                    dashcards yes, go to the dashcard designer and 
                    create one, it's easy.
                </p>
                <select value={cardId} onChange={event => setCardId(event.target.value)}>
                    <option value='0'>Default/Built in</option>
                    {dashcards.map(dc => <option key={dc.id} value={dc.id}>{dc.name}</option>)}
                </select>
            </Modal>
        </div>
    )
}

export default Registrations