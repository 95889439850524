import React, { useEffect, useState } from 'react';
import Members from './membership/members';
import Tags from './memberTags/tags';
import MemberTypes from './memberTypes/memberTypes';
import { MemberTypesProvider } from 'contexts/memberTypes';
import { TagsProvider } from 'contexts/tags';
import { MembersProvider } from 'contexts/members';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrowser, faCalendarAlt, faFile, faIdCard, faStore, faTags, faUserCog } from '@fortawesome/pro-regular-svg-icons';
import Files from './files/files';
import { FilesProvider } from 'contexts/files';
import { ActivitiesProvider } from 'contexts/activities';
import Activities from './activities/activities';
import Store from './store';
import { StoreProvider } from 'contexts/store';
import {permission, hasPermission} from 'util/permission';
import { useAuth } from 'contexts/auth';
import { PageProvider } from 'contexts/page';
import Pages from 'routes/pages';

export async function loader({params}) {
    return params;
}

function Club() {
    const { user } = useAuth();
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const [tag, setTag] = useState(null)
    const [view, setView] = useState(null);
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (loaderData) {
            setView(loaderData.view ? loaderData.view : null);
            setTag(loaderData.tag ? loaderData.tag : null);
        }
    }, [loaderData])

    useEffect(() => {
        switch (view) {
            case 'members': setTitle(': Members'); break;
            case 'tags': setTitle(': Member Tags'); break;
            case 'types': setTitle(': Member Types'); break;
            case 'files': setTitle(': Files'); break;
            case 'activities': setTitle(': Activities Calender'); break;
            case 'store': setTitle(': Store'); break;
            case 'pages': setTitle(': Pages'); break;
            default: setTitle(''); break;
        }
    }, [view]);

    const gotoPages = () => {

    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>{`Club ${title}`}</div>
                </div>
            </div>
            <MemberTypesProvider>
                <MembersProvider tag={tag}>
                    <TagsProvider>
                        {!view &&
                            <div className='showLanding'>
                                {hasPermission(user, permission.MEMBERSHIP) &&
                                <>
                                    <div className='showLandingTile' onClick={() => navigate('/admin/club/members')}>
                                        <FontAwesomeIcon icon={faUserCog} size='6x'/>
                                        <h6>Members</h6>
                                    </div>
                                    <div className='showLandingTile' onClick={() => navigate('/admin/club/tags')}>
                                        <FontAwesomeIcon icon={faTags} size='6x'/>
                                        <h6>Member Tags</h6>
                                    </div>
                                    <div className='showLandingTile' onClick={() => navigate('/admin/club/types')}>
                                        <FontAwesomeIcon icon={faIdCard} size='6x'/>
                                        <h6>Member Types</h6>
                                    </div>
                                    <div className='showLandingTile' onClick={() => navigate('/admin/club/files')}>
                                        <FontAwesomeIcon icon={faFile} size='6x'/>
                                        <h6>Files</h6>
                                    </div>
                                    <div className='showLandingTile' onClick={() => navigate('/admin/club/activities')}>
                                        <FontAwesomeIcon icon={faCalendarAlt} size='6x'/>
                                        <h6>Activities Calendar</h6>
                                    </div>
                                </>
                                }
                                {hasPermission(user, permission.STOREADMIN) &&
                                    <div className='showLandingTile' onClick={() => navigate('/admin/club/store/products')}>
                                        <FontAwesomeIcon icon={faStore} size='6x'/>
                                        <h6>Store</h6>
                                    </div>
                                }
                                {hasPermission(user, permission.ACCOUNTADMIN) &&
                                    <div className='showLandingTile' onClick={() => {alert('Pages is being replaced, please only use Pages to manage any web pages you created.');navigate('/admin/club/pages')}}>
                                        <FontAwesomeIcon icon={faBrowser} size='6x'/>
                                        <h6>Pages</h6>
                                    </div>
                                }
                            </div>
                        }
                        {view === 'members' &&
                            <Members />
                        }
                        {view === 'tags' && 
                            <Tags />
                        }
                        {view === 'files' &&
                            <FilesProvider><Files/></FilesProvider>
                        }
                        {view === 'activities' &&
                            <ActivitiesProvider>
                                <Activities />
                            </ActivitiesProvider>
                        }
                        {view === 'store' &&
                            <StoreProvider><Store /></StoreProvider>
                        }
                        {view === 'pages' &&
                            <PageProvider><Pages /></PageProvider>
                        }
                    </TagsProvider>
                    {view === 'types' && <MemberTypes />}
                </MembersProvider>
            </MemberTypesProvider>
        </div>
    )
}

export default Club;