import React, { useState } from 'react';
import AmazingTabs from 'components/amazingTabs';
import { ClassesProvider } from 'contexts/classes';
import { RegistrationsProvider } from 'contexts/registrations';
import { EventDashcardProvider } from 'contexts/eventDashcard';
import Registrations from './registrations';
import { useEvent } from 'contexts/event';
import { EventRegistrationOptionsProvider } from 'contexts/eventRegistrationOptions';
import Options from './options';

const tabs = [
    {id: 'registrations', label: 'Registrations'},
    {id: 'options', label: 'Registration Options'},
]

function Registration() {
    const { event } = useEvent();
    const [view, setView] = useState('registrations');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Registrations</div>
                </div>
            </div>
            <AmazingTabs tabs={tabs} selected={view} 
                onClick={newView => setView(newView.id)}></AmazingTabs>
            {view === 'registrations' &&
                <ClassesProvider eventId={event.id}>
                    <RegistrationsProvider eventId={event.id}>
                        <EventDashcardProvider eventId={event.id}>
                            <Registrations event={event} />
                        </EventDashcardProvider>
                    </RegistrationsProvider>
                </ClassesProvider>
            }
            {view === 'options' &&
                <EventRegistrationOptionsProvider eventId={event.id}>
                    <Options />
                </EventRegistrationOptionsProvider>
            }
        </div>
    )
}

export default Registration;