import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EmailTemplateContext = React.createContext(null);

function EmailTemplateProvider({templateId, children}) {
    const { runAction } = useCore();
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        loadTemplate();
    }, [templateId])

    const loadTemplate = async () => {
        setLoadingTemplate(true);
        await runAction('get_email_template', {id: templateId}, response => {
            setTemplate(response.template);
            setLoadingTemplate(false);
        })
    }

    const provider = {
        loadingTemplate,
        template
    }

    return <EmailTemplateContext.Provider value={provider}>{children}</EmailTemplateContext.Provider>
}

function useEmailTemplate() {
    const context = React.useContext(EmailTemplateContext);
    if (!context) {
        throw new Error('useEmailTemplate must be used within an EmailTemplateProvider');
    }
    return context;
}

export { EmailTemplateProvider, useEmailTemplate }