import React, { useEffect, useState } from 'react';
import Core from 'util/core';
import logo from 'assets/MTTest2.svg';
import { formatPhoneNumber, formatCurrency } from '_base';
import moment from 'moment';
import { useLoaderData } from 'react-router-dom';
import 'style/invoice.scss';

export async function loader({params}) {
    return params;
}

function Invoice() {
    const loaderData = useLoaderData();
    const [tenant, setTenant] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [items, setItems] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        getTenant();
        getInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTenant = () => {
        Core.runAction('get_tenant', {tenant: loaderData.tenant}, response => {
            setTenant(response.tenant);
        })
    }

    const getInvoice= () => {
        let data = {tenantId: loaderData.tenant, invoiceId: loaderData.invoice}
        Core.runAction('get_sub9_invoice', data, response => {
            setInvoice(response.invoice);
            setItems(response.items);
        })
    }

    const payNow = () => {
        if (processing) {
            return;
        }
        setProcessing(true);
        Core.runAction('pay_sub9_invoice', {id: invoice.id}, response => {
            setProcessing(false);
            if (response.paid) {
                getInvoice();
            } else {
                setErrorMessage(response.processInvoiceResponseMessage);
            }
        })
    }

    return (
        <div className='invoice-page portrait-printable-area portrait'>
            <div className='invoice-outer'>
                <div className='invoice-inner'>
                    <div className='logo-container'><img src={logo} alt='Middletwin Logo' /></div>
                    <div className='invoice-container'>
                        {tenant && 
                            <div className='addr'>
                                <div>Invoice For:</div>
                                <div>{tenant.name}</div>
                                <div>Attn:{tenant.contact}</div>
                                <div>{tenant.address1}</div>
                                <div>{tenant.city} {tenant.state},{tenant.state}</div>
                                <div>{formatPhoneNumber(tenant.phone)}</div>
                            </div>
                        }
                        {invoice &&
                            <div className='invoice'>
                                <div>Invoice: {invoice.id}</div>
                                <div>Created: {moment(invoice.created).format('YYYY-MM-DD')}</div>
                                <div>Due: {moment(invoice.dueDate).format('YYYY-MM-DD')}</div>
                                <div>Paid: {invoice.paidDate ? moment(invoice.paidDate).format('YYYY-MM-DD') : 'Not Paid'}</div>
                                <table cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr><th>Description</th><th>Cost</th></tr>
                                        {items.map(item => <tr key={item.id}><td>{item.description}</td><td>{formatCurrency(item.amount)}</td></tr>)}
                                        <tr><td>Total:</td><td>{formatCurrency(items.reduce((a, b) => {return a+b.amount}, 0))}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className='addr'>
                            <div>Remit payment to:</div>
                            <div>Middletwin, LLC</div>
                            <div>4836 Quail Ave N</div>
                            <div>Crystal, MN 55429</div>
                            <div>Terms: 15 days</div>
                        </div>
                        {invoice && !invoice.paidDate ?
                            <>
                                <button onClick={payNow} disabled={processing}>{processing ? 'Processing...' : 'Pay Now'}</button>
                                {errorMessage && <p>{errorMessage}</p>}
                            </>
                            : null
                        }
                    </div>
                    <p>Thank you for your business.</p>
                </div>
            </div>
        </div>
    )
}

export default Invoice;