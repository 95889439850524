import React, {useEffect, useState} from 'react';
import QRCode from 'react-qr-code';
import { useLoaderData } from 'react-router-dom';

export async function loader({params}) {
    return params;
}

function VoteCodes() {
    const loaderData = useLoaderData();
    const [codes, setCodes] = useState([]);
    
    useEffect(() => {
        let items = [];
        let start = parseInt(loaderData.start);
        for (let i = start; i < (start + 30); i++) {
            items.push(<div className='mailing-label' key={i}>
                <div className='content' style={{width:'60px',height:'60px'}}><QRCode size={60} value={`https://app.middletwin.com/vote/${loaderData.tenant}/${loaderData.event}/${i}`}/></div>
                <div className='content' style={{width:'72px',textAlign:'center',fontSize:'10px'}}>{i}</div>
                </div>)
        }
        setCodes(items);
    }, [loaderData.event, loaderData.start, loaderData.tenant])

    return (
        <div className='portrait-printable-area portrait'>
            <div className='mailing-labels-page'>
                <div className='mailing-labels-inner'>
                    {codes}
                </div>
            </div>
        </div>
    )
}

export default VoteCodes;