import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Shows from './shows';
import { EventsProvider } from 'contexts/events';

export async function loader({params}) {
    return params;
}

function Events({}) {
    const loaderData = useLoaderData();

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Events</div>
                </div>
            </div>
            <EventsProvider>
                <Shows showId={loaderData.showId} />
            </EventsProvider>
        </div>
    )
}

export default Events;