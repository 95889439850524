import { faAngleLeft, faCar, faHorizontalRule, faImage, faPrint, faQrcode, faSquare, faText, faThLarge, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HiddenCanvas from 'components/hiddenCanvas';
import IconButton from 'components/iconButton';
import Toggle from 'components/toggle';
import WaitButton from 'components/waitButton';
import { useEvent } from 'contexts/event';
import { useEventDashcard } from 'contexts/eventDashcard';
import { useEventRegistrationOptions } from 'contexts/eventRegistrationOptions';
import React, { useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import { useNavigate } from 'react-router-dom';
import 'style/eventDashcard.scss';
import 'style/print.scss';

function DashcardDesignStudio({dashcard, onDone}) {
    const { createDashcard, updateDashcard } = useEventDashcard();
    const { event } = useEvent();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [boxes, setBoxes] = useState([]);
    const [selectedBox, setSelectedBox] = useState(-1);
    const paperRef = useRef(null);
    const [showGridLines, setShowGridLines] = useState(false);

    useEffect(() => {
        if (dashcard) {
            setName(dashcard.name);
            setBoxes(JSON.parse(dashcard.content));
        }
    }, [dashcard])

    const saveHandler = () => {
        setIsSaving(true);
        const data = {
            name,
            content: JSON.stringify(boxes)
        }
        if (dashcard && dashcard.id) {
            updateDashcard({...dashcard, ...data});
        } else {
            createDashcard(data);
        }
        setIsSaving(false);
        onDone();
    }

    const handleDragStart = (e, type) => {
        e.dataTransfer.setData('type', type);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const type = e.dataTransfer.getData('type');
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        let defaultBoxData = {imageData: '', position: 'cover'};
        if (type === 'text') {
            defaultBoxData = {text: '', fontSize: 18, fontWeight: '400'};
        } else if (type === 'line' || type === 'border') {
            defaultBoxData = {stroke:1, style:'solid', color:'black'};
        } else if (type === 'border') {
            defaultBoxData = {stroke:1, style:'solid', color:'black', radius: 'none', imageData: '', position: 'cover', opacity: 0.5};
        } else if (type === 'registration') {
            defaultBoxData = {dataItem: 'carNumber', fontSize: 18, fontWeight: '400'};
        } else if (type === 'qrcode') {
            defaultBoxData = {width: 300, height: 150}
        }
        setBoxes([...boxes, {type, x, y, width: 100, height: 100, ...defaultBoxData}]);
        setSelectedBox(boxes.length);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const removeBoxHandler = () => {
        if (selectedBox > -1) {
            const newBoxes = boxes.filter((_, index) => index !== selectedBox);
            setBoxes(newBoxes);
            setSelectedBox(-1);
        }
    };

    const selectBox = (e, boxIndex) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('selectBox ', boxIndex);
        setSelectedBox(boxIndex);
    }

    const deSelectBoxes = (e) => {
        e.preventDefault();
        console.log('deSelectBoxes');
        setSelectedBox(-1);
    }

    const updateBoxImage = (values) => {
        let newBoxes = [...boxes];
        newBoxes[selectedBox].imageData = values.imageData;
        newBoxes[selectedBox].position = values.position;
        setBoxes(newBoxes);
    }

    const updateBoxText = (values) => {
        let newBoxes = [...boxes];
        newBoxes[selectedBox].text = values.text;
        newBoxes[selectedBox].fontSize = values.fontSize;
        newBoxes[selectedBox].fontWeight = values.fontWeight;
        setBoxes(newBoxes);
    }

    const updateBoxLine = (values) => {
        let newBoxes = [...boxes];
        newBoxes[selectedBox].stroke = values.stroke;
        newBoxes[selectedBox].style = values.style;
        newBoxes[selectedBox].color = values.color;
        setBoxes(newBoxes);
    }

    const updateBoxBorder = (values) => {
        let newBoxes = [...boxes];
        newBoxes[selectedBox].stroke = values.stroke;
        newBoxes[selectedBox].style = values.style;
        newBoxes[selectedBox].color = values.color;
        newBoxes[selectedBox].radius = values.radius;
        newBoxes[selectedBox].imageData = values.imageData;
        newBoxes[selectedBox].position = values.position;
        newBoxes[selectedBox].opacity = values.opacity;
        setBoxes(newBoxes);
    }

    const updateBoxDataItem = (values) => {
        let newBoxes = [...boxes];
        newBoxes[selectedBox].dataItem = values.dataItem;
        newBoxes[selectedBox].fontSize = values.fontSize;
        newBoxes[selectedBox].fontWeight = values.fontWeight;
        setBoxes(newBoxes);
    }

    const printHandler = () => {
        window.print();
//        if (dashcard) {
//            navigate(`/dashcard/${dashcard.id}/${event.id}`);
  //      }
    }

    return (
        <div className='dashcard-design-studio'>
            <div className='dashcard-settings'>
                <div className='breadcrumb' onClick={() => onDone()}>
                    <div className='breadcrumb-label'>
                        <FontAwesomeIcon icon={faAngleLeft} /> Back
                    </div>
                </div>
                <div className='dashcard-name-container'>
                    <label>Dashcard name</label>
                    <input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64' />
                </div>
                <div style={{display: 'flex',alignItems:'center'}}>
                    |
                    <Toggle checked={showGridLines} onChange={val => setShowGridLines(val)} />
                    <label style={{margin:'0'}}>Grid Lines</label>
                    |
                    <IconButton icon={faPrint} label='Print' onClick={printHandler}/>
                    |
                    <WaitButton onClick={saveHandler} waitMode={isSaving} label='Save' />
                </div>
            </div>
            <div className='dashcard-editor-container'>
                <div className='dashcard-editor-area'>
                    <div className='paper-container dashcard-container'>
                        <div className='paper landscape-printable-area landscape hide-data' 
                            ref={paperRef}
                            onDrop={handleDrop} 
                            onDragOver={handleDragOver} 
                            onClick={deSelectBoxes}>
                            {showGridLines && <div className='grid-lines'></div>}
                            {boxes.map((box, index) => (
                                <Rnd
                                    key={index}
                                    default={{
                                        x: box.x,
                                        y: box.y,
                                        width: box.width,
                                        height: box.height,
                                    }}
                                    minWidth={50}
                                    minHeight={50}
                                    bounds="parent"
                                    enableResizing={{
                                        top: true,
                                        right: true,
                                        bottom: true,
                                        left: true,
                                        topRight: true,
                                        bottomRight: true,
                                        bottomLeft: true,
                                        topLeft: true,
                                    }}
                                    style={{ zIndex: box.type === 'border' ? 0 : 1}}
                                    onDragStop={(e, d) => {
                                        const newBoxes = [...boxes];
                                        newBoxes[index] = {
                                            ...newBoxes[index],
                                            x: d.x,
                                            y: d.y,
                                        };
                                        setBoxes(newBoxes);
                                    }}
                                    onResizeStop={(e, direction, ref, delta, position) => {
                                        const newBoxes = [...boxes];
                                        newBoxes[index] = {
                                            ...newBoxes[index],
                                            width: ref.offsetWidth,
                                            height: ref.offsetHeight,
                                            x: position.x,
                                            y: position.y,
                                        };
                                        setBoxes(newBoxes);
                                    }}
                                >
                                    <Box boxId={index} box={box} isSelected={selectedBox === index} onSelect={selectBox} />
                                </Rnd>
                            ))}
                        </div>
                    </div>
                </div>
            
                <div className='dashcard-tools-area'>
                    {selectedBox > -1
                    ? 
                        <div className='box-details'>
                            
                            <div className='box-actions'>
                                <IconButton icon={faThLarge} label='Tiles' onClick={deSelectBoxes}/>
                                <IconButton icon={faTrash} label='Remove' onClick={removeBoxHandler}/>
                            </div>
                            {boxes[selectedBox].type === 'image' &&
                                <ImageBox value={boxes[selectedBox]} onChange={updateBoxImage} />
                            }
                            {boxes[selectedBox].type === 'text' &&
                                <TextBox value={boxes[selectedBox]} onChange={updateBoxText} />
                            }
                            {boxes[selectedBox].type === 'line' &&
                                <LineBox value={boxes[selectedBox]} onChange={updateBoxLine} />
                            }
                            {boxes[selectedBox].type === 'border' &&
                                <BorderBox value={boxes[selectedBox]} onChange={updateBoxBorder} />
                            }
                            {boxes[selectedBox].type === 'registration' &&
                                <RegistrationBox value={boxes[selectedBox]} onChange={updateBoxDataItem}/>
                            }
                        </div>
                    :
                        <div className='tiles-view'>
                            <p>Drag and drop tiles into the paper area to create your dash card</p>
                            <div className='tiles'>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'image')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faImage} />
                                    </div>
                                    <div className='tile-label'>Image</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'text')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faText} />
                                    </div>
                                    <div className='tile-label'>Text</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'line')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faHorizontalRule} />
                                    </div>
                                    <div className='tile-label'>Line</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'border')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faSquare} />
                                    </div>
                                    <div className='tile-label'>Border</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'registration')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faCar} />
                                    </div>
                                    <div className='tile-label'>Registration</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'qrcode')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faQrcode} />
                                    </div>
                                    <div className='tile-label'>Spectator</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function Box({boxId, box, isSelected, onSelect}) {

    return (
        <div className={`box${isSelected ? ' selected': ''}`} onClick={(e) => onSelect(e, boxId)}>
            {box.type === 'image' &&
                (box.imageData && box.imageData.length > 0
                ? <div className='image-box' style={{
                    backgroundImage:`url(${box.imageData})`, 
                    backgroundSize:box.position}} />
                : <div>Select to edit</div>)
            }
            {box.type === 'text' && (box.text && box.text.length > 0
                ? <div style={{fontSize:box.fontSize+'px', fontWeight: box.fontWeight}}>{box.text}</div>
                :<div>Select to edit</div>)
            }
            {box.type === 'line' && 
                <div className='line-box' style={{borderBottom:`${box.stroke}px ${box.style} ${box.color}`}}/>
            }
            {box.type === 'border' && 
                <div className='border-box' 
                    style={{
                        border:`${box.stroke}px ${box.style} ${box.color}`, 
                        borderRadius: box?.radius ? box.radius : 'none',
                    }}>
                        <div className='border-box-background' 
                            style={{
                                backgroundImage:`url(${box.imageData})`, 
                                backgroundSize: box.position,
                                opacity: box.opacity
                            }}
                        />
                    </div>
            }
            {box.type === 'registration' &&
                <div className='registration-box'
                    style={{fontSize:box.fontSize+'px', fontWeight: box.fontWeight}}>{`<${box.dataItem}>`}</div>
            }
            {box.type === 'qrcode' &&
                <div className='qrcode-box'>
                    <div className='qrcode-instructions'>
                    Use the camera on your phone to vote for this car.
                    </div>
                    <div className='qrcode-code'>{`$qrCode`}</div>
                </div>
            }
        </div>
    )
}

function ImageBox({value, onChange}) {
    const [imageData, setImageData] = useState('');
    const [position, setPosition] = useState('cover');

    useEffect(() => {
        if (value?.imageData !== imageData) {
            setImageData(value.imageData);
        }
        if (value?.position !== position) {
            setPosition(value.position);
        }
    }, [value])

    useEffect(() => {
        if (imageData !== value?.imageData || position !== value?.position) {
            onChange({imageData, position});
        }
    }, [imageData, position])

    const uploadImageHandler = (uploadedImageData) => {
        setImageData(uploadedImageData);
    }

    return (
        <>
            <HiddenCanvas buttonTitle='Upload image' width={400} height={400} onChange={uploadImageHandler}/>
            <label>Image position</label>
            <select vale={position} onChange={e => setPosition(e.target.value)}>
                <option value='contain'>Contain</option>
                <option value='cover'>Cover</option>
            </select>
        </>
    )
}

function TextBox({value, onChange}) {
    const [text, setText] = useState('');
    const [fontSize, setFontSize] = useState(18);
    const [fontWeight, setFontWeight] = useState('400')

    useEffect(() => {
        if (value?.text !== text) {
            setText(value.text);
        }
        if (value?.fontSize !== fontSize) {
            setFontSize(value.fontSize)
        }
        if (value?.fontWeight !== fontWeight) {
            setFontWeight(value.fontWeight);
        }
    }, [value])

    useEffect(() => {
        console.log('update ', text, ' fontSize ', fontSize, ' fontWeight ', fontWeight );
        if (text !== value?.text || fontSize !== value?.fontSize || fontWeight !== value?.fontWeight) {
            onChange({text, fontSize, fontWeight});
        }
    }, [text, fontSize, fontWeight])

    return (
        <>
            <label>Text</label>
            <input type='text' value={text} onChange={e => setText(e.target.value)} />
            <label>Font Size</label>
            <input type='number' value={fontSize} onChange={e => setFontSize(e.target.value)} 
                min={9} max={100} step={1} />
            <label>Font Weight</label>
            <select value={fontWeight} onChange={e => setFontWeight(e.target.value)}>
                <option value='100'>Thin</option>
                <option value='200'>Extra Light</option>
                <option value='300'>Light</option>
                <option value='400'>Normal</option>
                <option value='500'>Medium</option>
                <option value='600'>Semi Bold</option>
                <option value='700'>Bold</option>
                <option value='800'>Extra Bold</option>
                <option value='900'>Black</option>
            </select>
        </>
    )
}

function LineBox({value, onChange}) {
    const [stroke, setStroke] = useState(1);
    const [style, setStyle] = useState('solid');
    const [color, setColor] = useState('#000000');

    useEffect(() => {
        if (value?.stroke !== stroke) {
            setStroke(value.stroke);
        }
        if (value?.style !== style) {
            setStyle(value.style)
        }
        if (value?.color !== color) {
            setColor(value.color);
        }
    }, [value])

    useEffect(() => {
        console.log('LineBox useEffect stroke ', stroke, ' style ', style, ' color', color);
        if (stroke !== value?.stroke || style !== value?.style || color !== value.color) {
            onChange({stroke, style, color});
        }
    }, [stroke, style, color])

    return (
        <>
            <label>Stroke</label>
            <input type='number' value={stroke} onChange={e => setStroke(e.target.value)} 
                min={1} max={10} step={1} />
            <label>Style</label>
            <select value={style} onChange={e => setStyle(e.target.value)}>
                <option value='solid'>Solid</option>
                <option value='dotted'>Dotted</option>
                <option value='dashed'>Dashed</option>
                <option value='double'>Double</option>
                <option value='groove'>Groove</option>
            </select>
            <label>Color</label>
            <input type='text' value={color} onChange={e => setColor(e.target.value)} maxLength={20} />
        </>
    )
}

function BorderBox({value, onChange}) {
    const [stroke, setStroke] = useState(1);
    const [style, setStyle] = useState('solid');
    const [color, setColor] = useState('#000000');
    const [radius, setRadius] = useState('none');
    const [imageData, setImageData] = useState('');
    const [position, setPosition] = useState('cover');
    const [opacity, setOpacity] = useState('1.0');

    useEffect(() => {
        if (value?.stroke !== stroke) {
            setStroke(value.stroke);
        }
        if (value?.style !== style) {
            setStyle(value.style)
        }
        if (value?.radius !== radius) {
            setRadius(value.radius);
        }
        if (value?.color !== color) {
            setColor(value.color);
        }
        if (value?.imageData !== imageData) {
            setImageData(value.imageData);
        }
        if (value?.position !== position) {
            setPosition(value.position);
        }
        if (value?.opacity != opacity) {
            setOpacity(value.opacity);
        }
    }, [value])

    useEffect(() => {
        if (stroke !== value?.stroke 
            || style !== value?.style 
            || radius !== value?.radius
            || color !== value?.color
            || imageData !== value?.imageData
            || position !== value?.position
            || opacity !== value?.opacity
        ) {
            onChange({stroke, style, radius, color, imageData, position, opacity});
        }
    }, [stroke, style, radius, color, imageData, position, opacity])

    return (
        <>
            <label>Stroke</label>
            <input type='number' value={stroke} onChange={e => setStroke(e.target.value)} 
                min={1} max={10} step={1} />
            <label>Style</label>
            <select value={style} onChange={e => setStyle(e.target.value)}>
                <option value='solid'>Solid</option>
                <option value='dotted'>Dotted</option>
                <option value='dashed'>Dashed</option>
                <option value='double'>Double</option>
                <option value='groove'>Groove</option>
            </select>
            <label>Corner Radius</label>
            <select value={radius} onChange={e => setRadius(e.target.value)}>
                <option value='none'>None</option>
                <option value='1px'>1</option>
                <option value='2px'>2</option>
                <option value='3px'>3</option>
                <option value='4px'>4</option>
                <option value='5px'>5</option>
                <option value='6px'>6</option>
                <option value='7px'>7</option>
                <option value='8px'>8</option>
                <option value='9px'>9</option>
                <option value='10px'>10</option>

            </select>
            <label>Color</label>
            <input type='text' value={color} onChange={e => setColor(e.target.value)} maxLength={20} />
            <hr/>
            <label>Background Image (optional)</label>
            <HiddenCanvas buttonTitle='Upload background image' width={400} height={400} onChange={setImageData}/>
            <label>Image size</label>
            <select vale={position} onChange={e => setPosition(e.target.value)}>
                <option value='contain'>Contain</option>
                <option value='cover'>Cover</option>
                <option value='100%'>100%</option>
                <option value='95%'>95%</option>
                <option value='90%'>90%</option>
                <option value='85%'>85%</option>
                <option value='80%'>80%</option>
                <option value='75%'>75%</option>
                <option value='70%'>70%</option>
                <option value='60%'>60%</option>
                <option value='50%'>50%</option>
            </select>
            <label>Image opacity</label>
            <input type='number' value={opacity} onChange={e => setOpacity(e.target.value)} min={0.0} max={1.0} step={0.1} />
        </>
    )
}

function RegistrationBox({value, onChange}) {
    const { options } = useEventRegistrationOptions();
    const [dataItem, setDataItem] = useState('');
    const [fontSize, setFontSize] = useState(18);
    const [fontWeight, setFontWeight] = useState('400');

    useEffect(() => {
        if (value?.dataItem !== dataItem) {
            setDataItem(value.dataItem);
        }
        if (value?.fontSize !== fontSize) {
            setFontSize(value.fontSize)
        }
        if (value?.fontWeight !== fontWeight) {
            setFontWeight(value.fontWeight);
        }
    }, [value])

    useEffect(() => {
        if (dataItem !== value?.dataItem || fontSize !== value?.fontSize || fontWeight !== value?.fontWeight) {
            onChange({dataItem, fontSize, fontWeight});
        }
    }, [dataItem, fontSize, fontWeight])

    return (
        <>
            <label>Registration data</label>
            <select value={dataItem} onChange={e => setDataItem(e.target.value)}>
                <option value='carNumber'>Car Number</option>
                <option value='carClass'>Class Number and Description</option>
                <option value='classNum'>Class Number only</option>
                <option value='classSection'>Class Section</option>
                <option value='classLabel'>Class Description only</option>
                <option value='year'>Year</option>
                <option value='make'>Make</option>
                <option value='model'>Model</option>
                <option value='trim'>Trim</option>
                <option value='color'>Color</option>
                <option value='engine'>Engine</option>
                <option value='name'>Name</option>
                <option value='city'>City</option>
                <option value='state'>State</option>
                <option value='citystate'>City, State</option>
                {options.map(opt => <option key={opt.id} value={`option-${opt.id}`}>{opt.label}</option>)}
            </select>
            <label>Font Size</label>
            <input type='number' value={fontSize} onChange={e => setFontSize(e.target.value)} 
                min={9} max={100} step={1} />
            <label>Font Weight</label>
            <select value={fontWeight} onChange={e => setFontWeight(e.target.value)}>
                <option value='100'>Thin</option>
                <option value='200'>Extra Light</option>
                <option value='300'>Light</option>
                <option value='400'>Normal</option>
                <option value='500'>Medium</option>
                <option value='600'>Semi Bold</option>
                <option value='700'>Bold</option>
                <option value='800'>Extra Bold</option>
                <option value='900'>Black</option>
            </select>
        </>
    )
}

export default DashcardDesignStudio;