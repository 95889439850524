import React from 'react';
import Copyright from 'components/copyright';
import logo from 'assets/logo-white.png';
import Username from './username';
import 'style/login.scss';

function Login(props) {

    return(
        <div className='login-container'>
            <div className='logo-container'>
                <img src={logo} alt='Middletwin Logo' />
            </div>
            <div className='login-center-container'>
                <div className='login-center-pane-outer'>
                    <div className='login-heading'><h1>Admin Portal</h1></div>
                    <div className='login-center-pane-inner'>
                        <h2>Log in</h2>
                        <Username onLogin={props.onLogin} />
                        <hr/>
                        <div className="signup-container">
                            If you are an administrator for a car club or car show 
                            and need access to the Middletwin administration portal, 
                            please contact the person responsible for administering 
                            your groups Middletwin account to be added. We do no allow 
                            self signup for the administration portal.
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    );
}

export default Login;