import React, { useEffect, useState } from 'react';
import { useMakesAndModels } from 'contexts/makesAndModels';

function Models(props) {
    const { makes, models, getModels } = useMakesAndModels();
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');

    useEffect(() => {
        if (makes.length > 0 && make.length > 0) {
            const foundMake = makes.find(m => m.label === make);
            if (foundMake) {
                getModels(foundMake.id);
            }
        }
    }, [makes, make])

    useEffect(() => {
        // set make and model if they are set from the props
        setMake(props.make);
        setModel(props.model);
    }, [props.make, props.model]);

    const makeChangeHandler = (e) => {
        const makeValue = e.target.value;
        setMake(makeValue);
        setModel('');
    }

    const modelChangeHandler = (e) => {
        const modelValue = e.target.value;
        setModel(modelValue);
        props.onChange(make, modelValue);
    }

    return (
        <div>
            <label>Make*</label>
            <select value={make} onChange={makeChangeHandler}>
                <option value=''>-- Select --</option>
                {makes.map((m) => <option key={m.id} value={m.label}>{m.label}</option>)}
            </select>
            <label>Model*</label>
            <select value={model} onChange={modelChangeHandler}>
                <option value=''>-- Select --</option>
                {models.map(m => <option key={m.id} value={m.label}>{m.label}</option>)}
            </select>
        </div>
    )
}

export default Models;