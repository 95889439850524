import React, { useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useVendors } from 'contexts/vendors';
import Toggle from 'components/toggle';

function Vendors({eventId}) {
    const { getVendors, vendors, saveVendor, deleteVendors } = useVendors();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [email, setEmail] = useState('');
    const [allowEmailMarketing, setAllowEmailMarketing] = useState(true);
    const [phone, setPhone] = useState('');
    const [taxId, setTaxId] = useState('');
    const [vendorType, setVendorType] = useState(0);

    const [editingVendor, setEditingVendor] = useState(null);
    const vendorPanel = React.useRef();

    const selectVendor = (row) => {
        setName(row.name);
        setDescription(row.description);
        setAddress(row.address);
        setCity(row.city);
        setState(row.state);
        setZip(row.zip);
        setEmail(row.email);
        setAllowEmailMarketing(row.allowEmailMarketing);
        setPhone(row.phone);
        setTaxId(row.taxId);
        setVendorType(row.vendorType);
        setEditingVendor(row);
        vendorPanel.current.show();
    }

    const saveVendorHandler = () => {
        if (editingVendor) {
            const vendor = {...editingVendor, eventId, name, description, address, city, state, zip, email, allowEmailMarketing, phone, taxId, vendorType};
            saveVendor(vendor);
        } else {
            const vendor = {eventId, name, description, address, city, state, zip, email, allowEmailMarketing, phone, taxId, vendorType};
            saveVendor(vendor);
        }
        onCloseVendorPanel();
    }

    const onCloseVendorPanel = () => {
        setName('');
        setDescription('');
        setAddress('');
        setCity('');
        setState('');
        setZip('');
        setEmail('');
        setAllowEmailMarketing(true);
        setPhone('');
        setTaxId('');
        setVendorType(0);
        setEditingVendor(null);
        vendorPanel.current.hide();
    }

    const deleteVendorsHandler = (rows) => {
        const rowIds = rows.map((r) => r.id);
        deleteVendors(rowIds);
    }

    const handleExport = () => {
        var csvData = [["name", "email", "phone", "address", "city", 'state', 'zip', 'taxid']];
        vendors.forEach(v => csvData.push([v.name, v.email, v.phone, v.address, v.city, v.state, v.zip, v.taxId]));

        let csvContent = "data:text/csv;charset=utf-8," 
            + csvData.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    const getVendorTypeLabel = (typeId) => {
        let typeLabel = '';
        switch (typeId) {
            case 0: typeLabel = 'Unknown'; break;
            case 1: typeLabel = 'Swapper'; break;
            case 2: typeLabel = 'Food'; break;
            case 3: typeLabel = 'Merchandise'; break;
        }
        return typeLabel;
    }

    const columns = [
        {id: 'id', label: 'ID', display: false, datum: 'id'},
        {id: 'eventId', label: 'Event ID', display: false, datum: 'eventid'},
        {id: 'type', label: 'Type', display: true, style: {width:'100px'}, formatter: obj => getVendorTypeLabel(obj.vendorType)},
        {id: 'name', label: 'Name', display: true, datum: 'name'},
        {id: 'phone', label: 'Phone', display: true, style: {width:'100px'}, datum: 'phone'},
        {id: 'email', label: 'Email', display: true, datum: 'email'},
        {id: 'address', label: 'Address', display: true, style: {width:'100px'}, datum: 'address'},
        {id: 'city', label: 'City', display: true, style: {width:'100px'}, datum: 'city'},
        {id: 'state', label: 'State', display: true, style: {width:'100px'}, datum: 'state'},
        {id: 'zip', label: 'Zip', display: true, style: {width:'100px'}, datum: 'zip'},
        {id: 'taxid', label: 'Tax ID', display: true, style: {width:'100px'}, datum: 'taxid'},
    ];

    return (
        <>
            <TableSetupProvider tableName='vendors' defaultColumns={columns}>
                <AmazingTable 
                    rows={vendors}
                    onRefresh={getVendors}
                    onExport={handleExport}
                    onRowClick={selectVendor}
                    onRowSelect={() => {}}
                    onCreate={() => vendorPanel.current.show()}
                    onCreateLabel='Add vendor'
                    onDelete={deleteVendorsHandler} />
            </TableSetupProvider>
            <SidePanel title='Vendor' ref={vendorPanel}>
                <div>
                    <label>Name</label><br/>
                    <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength={64} />
                </div>
                <div>
                    <label>Description</label><br/>
                    <textarea
                        value={description} 
                        onChange={e => setDescription(e.target.value)}
                        style={{height:'200px',width:'100%'}} 
                        maxLength='2048'></textarea>
                </div>
                <div>
                    <label>Vendor Type</label>
                    <select value={vendorType} onChange={e => setVendorType(e.target.value)}>
                        <option value='0'>Unknown</option>
                        <option value='1'>Swapper</option>
                        <option value='2'>Food</option>
                        <option value='3'>Merchandise</option>
                    </select>
                </div>
                <div>
                    <label>Mailing address</label><br/>
                    <input type='text' value={address} onChange={e => setAddress(e.target.value)} maxLength={128} placeholder='Address'/>
                    <input type='text' value={city} onChange={e => setCity(e.target.value)} maxLength={64} placeholder='City'/>
                    <input type='text' value={state} onChange={e => setState(e.target.value)} maxLength={2} placeholder='State'/>
                    <input type='text' value={zip} onChange={e => setZip(e.target.value)} maxLength={10} placeholder='Zip'/>
                </div>
                <div>
                    <label>Email</label><br/>
                    <input type='email' value={email} onChange={e => setEmail(e.target.value)} maxLength={128} />
                </div>
                <div>
                    <label>Allow email marketing</label>
                    <Toggle checked={allowEmailMarketing} onChange={newVal => setAllowEmailMarketing(newVal)} />
                </div>
                <div>
                    <label>Phone</label><br/>
                    <input type='text' value={phone} onChange={e => setPhone(e.target.value)} maxLength={20} />
                </div>
                <div>
                    <label>Tax ID</label><br/>
                    <input type='text' value={taxId} onChange={e => setTaxId(e.target.value)} maxLength={20} />
                </div>
                <button onClick={onCloseVendorPanel}>Cancel</button>
                <button onClick={saveVendorHandler}>{editingVendor ? 'Save' : 'Add'}</button>
            </SidePanel>
        </>
    )
}

export default Vendors;