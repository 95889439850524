import React, { useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import Tag from './tag';
import SidePanel from 'components/sidePanel';
import { useAuth } from 'contexts/auth';
import { useTags } from 'contexts/tags';
import { useNavigate } from 'react-router-dom';
import { TableSetupProvider } from 'contexts/tableSetup';
import Modal from 'components/modal';

const tagsColumns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'text', label:'Tag', display: true, datum: 'text'},
    {id: 'count', label:'Count', display: true, datum: 'count'}
];

function Tags() {
    const { loadingTags, tags, loadTags, searchTags, saveTag, deleteTags } = useTags();
    const navigate = useNavigate();
    const [tag, setTag] = useState(null);
    const [showCleanupConfirmation, setShowCleanupConfirmation] = useState(false);
    const tagPanel = useRef(null);
    const auth = useAuth();

    const exportHandler = () => {
        window.open('/dart/core?action=get_all_tags&format=csv&tenantUUID=' + auth.tenant.uuid);
    }
    const save = (data) => {
        saveTag(data);
        tagPanel.current.hide();
    }
    const showTag = (tag) => {
        setTag(tag);
        tagPanel.current.show();
    }
    const createTag = () => {
        setTag({text: ''});
        tagPanel.current.show();
    }
    const hideTagPanel = () => {
        setTag(null);
    }
    const removeTags = (tags) => {
        deleteTags(tags.map(t => t.id));
    }
    const showMembers = () => {
        navigate('/admin/club/membership/members/' + tag.id);
    }
    const cleanHandler = () => {
        setShowCleanupConfirmation(true);
    }
    const removeUnusedTags = () => {
        const unused = tags.filter(t => t.count === 0);
        deleteTags(unused.map(t => t.id));
    }

    return (
        <div>
            <TableSetupProvider tableName='tags' defaultColumns={tagsColumns}>
                <AmazingTable 
                    rows={tags}
                    onRefresh={loadTags}
                    onTableChange={searchTags}
                    onExport={exportHandler}
                    onRowClick={showTag}
                    onCreate={createTag}
                    onCreateLabel='Add tag'
                    onDelete={removeTags}
                    onClean={cleanHandler}
                    loading={loadingTags} />
            </TableSetupProvider>
            <SidePanel title={tag ? tag.text : ''} ref={tagPanel} onHide={hideTagPanel}>
                {tag && <Tag tag={tag} onSave={save} onShowMembers={showMembers}/>}
            </SidePanel>
            <Modal 
                title='Remove unused tags' 
                showing={showCleanupConfirmation} 
                onHide={() => setShowCleanupConfirmation(false)}
                buttons={[
                    {type: 'primary', handler:() => {removeUnusedTags(); setShowCleanupConfirmation(false);},label: 'Yes, remove'},
                    {type: 'secondary', handler: () => setShowCleanupConfirmation(false), label: 'Cancel'}
                ]}>
                <div>Are you sure you want to remove the unused tags?</div>
            </Modal>
        </div>
    )
}

export default Tags;