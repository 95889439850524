import React from 'react';
import { usePermissions } from 'contexts/permissions';

function Permissions({userPermissions, onChange}) {
    const { permissions } = usePermissions();
    
    return (
        <div className="communication-area selected">
			<table>
                <tbody>
                    {
                        permissions.map((perm) => {
                            const userPermission = userPermissions.find((p) => p.id == perm.id);
                            const accessLevel = userPermission ? userPermission.accessLevel : 0;
                            return <tr key={perm.id}>
                                <td>
                                    <select value={accessLevel} onChange={e => onChange(perm, e.target.value)}>
                                        <option value='0'>No access</option>
                                        <option value='1'>Administrate</option>
                                        <option value='2'>View Only</option>
                                    </select>
                                </td>
                                <td>&nbsp;{perm.label}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
		</div>
    )
}

export default Permissions