import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EventRegistrationOptionContext = React.createContext(null);

function EventRegistrationOptionsProvider({eventId, children}) {
    const { runAction } = useCore();
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        loadOptions();
    }, [])

    const loadOptions = async () => {
        setLoadingOptions(true);
        await runAction('get_event_registration_options', {eventId}, response => {
            setOptions(response.options);
            setLoadingOptions(false);
        })
    }

    const createOption = async (data) => {
        await runAction('create_event_registration_option', {...data, eventId}, () => {
            loadOptions();
        })
    }

    const updateOption = async (data) => {
        await runAction('update_event_registration_option', data, () => {
            loadOptions();
        })
    } 

    const deleteOptions = async (ids) => {
        await runAction('delete_event_registration_option', {ids}, () => {
            loadOptions();
        })
    }

    const provider = {
        loadingOptions,
        options,
        loadOptions,
        createOption,
        updateOption,
        deleteOptions
    }

    return <EventRegistrationOptionContext.Provider value={provider}>{children}</EventRegistrationOptionContext.Provider>
}

function useEventRegistrationOptions() {
    const context = React.useContext(EventRegistrationOptionContext);
    if (!context) {
        throw new Error('useEventRegistrationOptions must be used within an EventRegistrationOptionsProvider')
    }
    return context;
}

export { EventRegistrationOptionsProvider, useEventRegistrationOptions }