import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBan, faCalendarAlt, faCheckCircle, faExclamationCircle, faPencil, faPlus, faSave, faTimes} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Preferences from './preferences';
import MemberPhoneNumber from './memberPhoneNumber';
import Loading from 'components/loading';
import { useMemberTypes } from 'contexts/memberTypes';
import { PhoneProvider } from 'contexts/phone';
import { MemberSettingsProvider } from 'contexts/memberSettings';
import { useMember } from 'contexts/member';
import 'style/Longform.scss';
import IconButton from 'components/iconButton';
import { useCountries } from 'contexts/countries';
import { publish } from 'pubsub-js';
import { useSettings } from 'contexts/settings';
import PillBox from 'components/tagPillbox';
import { PillBoxProvider } from 'contexts/tagPillbox';
import TagCreator from './tagCreator';
import { TagCreatorProvider } from 'contexts/tagCreator';
import { PaymentHistoryProvider } from 'contexts/paymentHistory';
import PaymentHistory from 'routes/admin/finance/paymentHistory';
import Avatar from 'components/avatar';
import Modal from 'components/modal';

function MemberDetails(props) {
    const { memberTypes } = useMemberTypes();
    const { member, updateMember, updateMemberId, fixMemberSettings, checkEmail, resetEmail, emailSuppression, suppressionLoading } = useMember();
    const { countries } = useCountries();
    const settings = useSettings();

    const [memberId, setMemberId] = useState(0);
    const [altMemberId, setAltMemberId] = useState('');
    const [avatar, setAvatar] = useState('');
    const [type, setType] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [title, setTitle] = useState('');
    const [partner, setPartner] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [comments, setComments] = useState('');
    const [parentId, setParentId] = useState();
    const [spam, setSpam] = useState(false);
    const [joinDate, setJoinDate] = useState(moment());
    const [expiration, setExpiration] = useState(moment());
    const [birthDate, setBirthDate] = useState(moment());
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [tags, setTags] = useState([]);
    const [settingsRequireFix, setSettingsRequireFix] = useState(false);

    const [twilioNumber, setTwilioNumber] = useState(null);
    const [tagFilter, setTagFilter] = useState('');
    const [showEmailSupression, setShowEmailSupression] = useState(false);

    const [addPhoneMode, setaddPhoneMode] = useState(false);
    const [addTagMode, setAddTagMode] = useState(false);
    const [editingPhoneNumber, setEditingPhoneNumber] = useState(null);
    const [editMemberNumber, setEditMemberNumber] = useState(false);
    const [newMemberNumber, setNewMemberNumber] = useState(0);
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);
    const section4 = useRef(null);
    const section5 = useRef(null);
    const section6 = useRef(null);
    const section7 = useRef(null);

    useEffect(() => {
        if (member) {
            setMemberId(member.id);
            setAltMemberId(member.altMemberId);
            setAvatar(member.avatar);
            setType(member.type)
            setFname(member.fname);
            setLname(member.lname);
            setTitle(member.title);
            setPartner(member.partner);
            setEmail(member.email_address);
            setAddress(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setCountry(member.country);
            setComments(member.comments);
            setParentId(member.parentId);
            setSpam(member.spam);
            setJoinDate(member.join_date || new Date().getTime());
            if (!member.expiration) {
                publish('warning', 'This members expiration date is invalid, please fix the expiration date and save the record.');
            }
            setExpiration(member.expiration || new Date().getTime());
            setBirthDate(member.birth_date);
            setPhoneNumbers(member.phoneNumbers);
            setTags(member.tags);
            setSettingsRequireFix(member.settings.length > 1);
        }
    }, [member])

    const lonformScrollHandler = (e) => {
        // unset all highlights
        document.querySelectorAll('h3').forEach(elem => elem.classList.remove('highlight'));
        // get the scroll top
        const scrollTop = e.target.scrollTop;
        if (scrollTop <= section1.current.offsetTop) {
            document.querySelector('h3:nth-of-type(1)').classList.add('highlight');
        } else if (scrollTop > section1.current.offsetTop && scrollTop <= section2.current.offsetTop) {
            document.querySelector('h3:nth-of-type(2)').classList.add('highlight');
        } else if (scrollTop > section2.current.offsetTop && scrollTop <= section3.current.offsetTop) {
            document.querySelector('h3:nth-of-type(3)').classList.add('highlight');
        } else if (scrollTop > section3.current.offsetTop && scrollTop <= section4.current.offsetTop) {
            document.querySelector('h3:nth-of-type(4)').classList.add('highlight');
        } else if (scrollTop > section4.current.offsetTop && scrollTop <= section5.current.offsetTop) {
            document.querySelector('h3:nth-of-type(5)').classList.add('highlight');
        } else if (scrollTop > section5.current.offsetTop && scrollTop <= section6.current.offsetTop) {
            document.querySelector('h3:nth-of-type(6)').classList.add('highlight');
        } else if (scrollTop > section6.current.offsetTop && scrollTop <= section7.current.offsetTop) {
            document.querySelector('h3:nth-of-type(7)').classList.add('highlight');
        }
    }

    // phone numbers
    const savePhoneNumber = (pn) => {
        let _phoneNumbers = [...phoneNumbers];
        if (pn.id) {
            removePhoneNumber(pn);
        }
        _phoneNumbers.push(pn);
        setPhoneNumbers(_phoneNumbers);
        setaddPhoneMode(false);
        setEditingPhoneNumber(null);
    }
    const removePhoneNumber = (pn) => {
        let _phoneNumbers = [...phoneNumbers];
        let idx = _phoneNumbers.findIndex(p => p.id === pn.id);
        _phoneNumbers.splice(idx, 1);
        setPhoneNumbers(_phoneNumbers);
        setaddPhoneMode(false);
        setEditingPhoneNumber(null);
    }
    const togglePhoneNumber = () => {
        if (addPhoneMode) {
            setaddPhoneMode(false);
            setEditingPhoneNumber(null);
        } else {
            setaddPhoneMode(true);
        }
    }
    const editPhoneNumber = (pn) => {
        setEditingPhoneNumber(pn);
        setaddPhoneMode(true);
    }
    const saveMember = () => {
        let data = {
            id: memberId,
            altMemberId, type, fname, lname, title, partner, email_address: email, address, city, state, zip, country, 
            comments, parentId, spam, join_date: joinDate, expiration, birth_date: birthDate, phoneNumbers, tags, avatar
        };
        updateMember(data, () => props.onSave());
    }

    const saveNewMemberNumberHandler = (evt) => {
        if (memberId !== newMemberNumber) {
            updateMemberId(memberId, newMemberNumber, (success) => {
                if (success) {
                    setMemberId(newMemberNumber);
                }
            });
        }
        setNewMemberNumber(0);
        setEditMemberNumber(false);
        evt.preventDefault();
        evt.stopPropagation();
    }

    const fixMemberSettingsHandler = () => {
        fixMemberSettings(() => setSettingsRequireFix(false));
    }

    const showEmailSupressionHandler = () => {
        setShowEmailSupression(true);
        checkEmail();
    }

    return(
        <div className='longform_outer'>
            {member ?
                <>
                <div className='longform_section_links_outer'>
                    <div className='longform_section_links_inner'>
                        <h3 className='highlight'><a href='#1'>Personal Details</a></h3>
                        <h3><a href='#2'>Contact Info</a></h3>
                        <h3><a href='#3'>Parent</a></h3>
                        <h3><a href='#3'>Member Settings</a>
                            {member.settings.length > 1 && <FontAwesomeIcon icon={faExclamationCircle} style={{color:'red'}} />}
                        </h3>
                        <h3><a href='#4'>Tags</a></h3>
                        <h3><a href='#5'>Membership</a></h3>
                        <h3><a href='#6'>Payment History</a></h3>
                    </div>
                </div>
                <div className='longform_sections_outer' onScroll={lonformScrollHandler}>
                    <div className='longform_sections_inner'>
                        <section id='1' ref={section1}>
                            <h4>Personal Details</h4>
                            <div className='details-row'>
                                <div className='details-column'>
                                <label>Member ID</label>
                                    <div style={{display: 'flex'}}>
                                    <input 
                                        type='number' 
                                        value={editMemberNumber ? newMemberNumber : memberId}
                                        onChange={e => setNewMemberNumber(e.target.value)}
                                        min={1}
                                        disabled={!editMemberNumber}
                                        />
                                    {editMemberNumber ?
                                        <IconButton icon={faSave} onClick={saveNewMemberNumberHandler} label='Save' />
                                        :
                                        <IconButton 
                                            icon={faPencil} 
                                            onClick={e => {setNewMemberNumber(member.id);setEditMemberNumber(true)}} 
                                            label='Edit' />
                                    }
                                    </div>
                                </div>
                                <div className='details-column'>
                                <label>{settings.tenant?.alternateIdLabel}</label>
                                <input type='number' 
                                    value={altMemberId} 
                                    onChange={e => setAltMemberId(e.target.value)} 
                                    min={0} />
                                </div>
                            </div>
                            <Avatar value={avatar} onChange={setAvatar} width='100' height='100' />
                            <label>First name</label>
                            <input type='text' value={fname} onChange={e => setFname(e.target.value)} maxLength="64" />
                            <label>Last name</label>
                            <input type='text' value={lname} onChange={e => setLname(e.target.value)} maxLength="64" />
                            <label>Title</label>
                            <select value={title} onChange={e => setTitle(e.target.value)}>
                                <option value=''>-None-</option>
                                <option value='Mr'>Mr</option>
                                <option value='Mrs'>Mrs</option>
                                <option value='Miss'>Miss</option>
                                <option value='Ms'>Ms</option>
                                <option value='Dr'>Dr</option>
                            </select>
                            <label>Partner</label>
                            <input type='text' value={partner} onChange={e => setPartner(e.target.value)} maxLength="64" />
                            <label>Address</label>
                            <input type='text' value={address} onChange={e => setAddress(e.target.value)} maxLength="64" />
                            <label>City</label>
                            <input type='text' value={city} onChange={e => setCity(e.target.value)} maxLength="64" />
                            <label>State</label>
                            <input type='text' value={state} onChange={e => setState(e.target.value)} maxLength="32" />
                            <label>Postal code</label>
                            <input type='text' style={{width:'200px'}} value={zip} onChange={e => setZip(e.target.value)} maxLength="16" />
                            <label>Country</label>
                            <select value={country} onChange={(evt) => setCountry(evt.target.value)}>
                                {countries.map((c) => <option key={c.isoCode} value={c.isoCode}>{`${c.label} (${c.countryCode})`}</option>)}
                            </select>
                            <label>Date of birth</label>
                            <div className='date-picker'>
                                <DatePicker selected={birthDate && moment(birthDate).toDate()} onChange={d => setBirthDate(d.getTime())}/>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                            <label>Comments</label>
                            <textarea value={comments} onChange={e => setComments(e.target.value)} maxLength="1024" />
                        </section>

                        <hr />
                        <section id='2' ref={section2}>
                            <h4>Contact Info</h4>
                            <label>Email</label>
                            <input type='email' value={email} onChange={e => setEmail(e.target.value)} maxLength="128" />
                            <div>
                                <button className='linkButton' onClick={showEmailSupressionHandler}>Check supression lists</button>
                            </div>
                            <label>Phone Numbers</label>
                            <table className='phone-number-table' cellPadding={0} cellSpacing={0}>
                                <thead>
                                    <tr><th>Number</th><th>Type</th><th>Country code</th><th>Use for messaging</th></tr>
                                </thead>
                                <tbody>
                                    {phoneNumbers.length > 0 ? 
                                        phoneNumbers.map(pn => 
                                            <tr key={pn.id} onClick={() => editPhoneNumber(pn)}>
                                                <td>{pn.longCode}</td>
                                                <td>{pn.typeLabel}</td>
                                                <td>{pn.isoCode}</td>
                                                <td>{pn.messaging ? 'Yes' : 'No'}</td>
                                            </tr>)
                                        : <tr><td colSpan={4} style={{textAlign:'center'}}>No phone numbers</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="add-button" onClick={togglePhoneNumber}><FontAwesomeIcon icon={addPhoneMode ? faTimes : faPlus} /> {addPhoneMode ? 'Cancel' : 'Add'}</div>
                            {addPhoneMode && 
                                <PhoneProvider>
                                    <MemberPhoneNumber
                                        twilioNumber={twilioNumber} 
                                        onSave={savePhoneNumber} 
                                        onRemove={removePhoneNumber} 
                                        phoneNumber={editingPhoneNumber} />
                                </PhoneProvider>
                            }
                        </section>
                        
                        <hr/>
                        <section id='3' ref={section3}>
                            <h4>Parent</h4>
                            <p>If this membership is a child membership under a parent, the parents member id will be here.</p>
                            <p>You can also set a parent id for a membership. Note that the system will check to see if the 
                                parent id exists, and ensure that it is not a member id of a child member, that is not allowed.
                                An error will be returned if any of these conditions fail. This will also automatically adjust 
                                this child members expiration date to the same date as the parent.
                            </p>
                            <label>Parent member id</label>
                            <input type='number' value={parentId} onChange={evt => setParentId(evt.target.value)} min={0} step={1} />
                        </section>

                        <hr />
                        <section id='4' ref={section4}>
                            <h4>Member Settings</h4>
                            {settingsRequireFix ?
                                <div>
                                    <p>
                                        <FontAwesomeIcon icon={faExclamationCircle} style={{color:'red'}} /> There are multiple settings. 
                                        <button onClick={() => fixMemberSettingsHandler()} className='linkButton'>Fix now</button>
                                    </p>
                                </div>
                            :
                                <>
                                <p>Members can change most of these settings in the member portal. You can remove some of these from the member portal in the Administration settings.</p>
                                <MemberSettingsProvider memberId={member.id}>
                                    <Preferences /> 
                                </MemberSettingsProvider>
                                </>
                            }
                            <p>For members who have forgotten their passwords, have them use the 'Forgot Password' link on the member portal login page.</p>
                            <p>*-Portal moderators can manage or delete social media posts or classifieds created by other members.</p>
                        </section>
                        <hr/>
                        <section id='5' ref={section5}>
                            <div className='header-row'>
                                <h4>Tags</h4>
                                <input type='text' placeholder='Filter' value={tagFilter} onChange={event => setTagFilter(event.target.value)} />
                                <button role='button' className="linkButton" onClick={() => setAddTagMode(true)}><FontAwesomeIcon icon={faPlus} /> Add tag</button>
                            </div>
                            <PillBoxProvider tags={member.tags} onChange={setTags}><PillBox filter={tagFilter}/></PillBoxProvider>
                        </section>

                        <hr/>
                        <section id='6' ref={section6}>
                            <h4>Membership</h4>
                            <div className='details-row'>
                                <div className='details-column'>
                                    <label>Membership Type</label>
                                    <select name='type' value={type} onChange={e => setType(e.target.value)}>
                                        {memberTypes.map((t) => <option key={t.id} value={t.id}>{t.label}</option>)}
                                    </select>
                                </div>
                                <div className='details-column'>
                                    <label>Join date</label>
                                    <div className='date-picker'>
                                        <DatePicker selected={moment(joinDate).toDate()} onChange={d => setJoinDate(d.getTime())}/>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </div>
                                </div>
                                <div className='details-column'>
                                    <label>Expiration</label>
                                    <div className='date-picker'>
                                        <DatePicker selected={moment(expiration).toDate()} onChange={d => setExpiration(d.getTime())}/>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id='7' ref={section7}>
                            <h4>Payment History</h4>
                            {!settingsRequireFix && member.settings[0]?.stripeCustomerId !== '' && member.settings[0]?.stripeCustomerId !== null ?
                                <PaymentHistoryProvider memberId={member.id}>
                                    <PaymentHistory />
                                </PaymentHistoryProvider>
                                : <p>This member has no payment history</p>
                            }
                        </section>
                        <hr/>
                        <div className="button-container">
                            <button className='secondary' onClick={props.onBack}>Cancel</button>
                            <button onClick={saveMember}>Save Member</button>
                        </div>
                    </div>
                </div>
                <TagCreatorProvider>
                    <TagCreator 
                        showing={addTagMode} 
                        onHide={() => setAddTagMode(false)} />
                </TagCreatorProvider>
                </>
                :
                <Loading />
            }
            <Modal
                title='Email suppression' showing={showEmailSupression} 
                onHide={() => {setShowEmailSupression(false)}}
                buttons={[
                    {type: 'primary', handler:() => resetEmail(), label: 'Reset all email suppression'},
                    {type: 'secondary', handler:() => setShowEmailSupression(false), label: 'Cancel'}
                ]}
            >
                <p style={{width:'600px'}}>There are 4 places where a members email can be blocked. The Middletwin unsubscribe list contains a 
                    list of email address where the member clicked the unsubscribe link in an email. The Sendgrid block list are emails that 
                    indicated they wanted to block incoming emails from the sender. Sendgrid bounce list contains email addresses that bounced. 
                    And the Sendgrid spam list contains email addresses of members who indicated that incoming email from the sender is spam.
                </p>
                <p style={{width:'600px'}}>Clicking the <span style={{fontWieght:'600'}}>Reset all email suppression</span> button will cause 
                    the system to attempt to remove this members email address from all these lists. This list will update with the status of 
                    the request.
                </p>
                <p style={{textAlign:'center'}}>
                    <FontAwesomeIcon style={{color:'green'}} icon={faBan} /> = email address not in suppression list<br/>
                    <FontAwesomeIcon style={{color:'red'}} icon={faCheckCircle} /> = email address is in suppression list
                </p>
                {suppressionLoading ? <Loading /> : emailSuppression && 
                    <ul style={{listStyle:'none'}}>
                        <li><FontAwesomeIcon 
                            style={{color:emailSuppression.blacklisted ? 'red' : 'green'}} 
                            icon={emailSuppression.blacklisted ? faCheckCircle : faBan} /> Middletwin unsubscribe list</li>
                        <li><FontAwesomeIcon 
                            style={{color:emailSuppression.sendGridBlock ? 'red' : 'green'}} 
                            icon={emailSuppression.sendGridBlock ? faCheckCircle : faBan} /> Sendgrid block list</li>
                        <li><FontAwesomeIcon 
                            style={{color:emailSuppression.sendGridBounce ? 'red' : 'green'}} 
                            icon={emailSuppression.sendGridBounce ? faCheckCircle : faBan} /> Sendgrid bounce list</li>
                        <li><FontAwesomeIcon 
                            style={{color:emailSuppression.sendGridSpam ? 'red' : 'green'}} 
                            icon={emailSuppression.sendGridSpam ? faCheckCircle : faBan} /> Sendgrid spam list</li>
                    </ul>
                }
            </Modal>
        </div>
    )
}


export default MemberDetails;