import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const MakesAndModelsContext = React.createContext(null);

function MakesAndModelsProvider({eventId, children}) {
    const { runAction } = useCore();
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);

    useEffect(() => {
        getMakes();
     }, []);
 
     const getMakes = async () => {
         await runAction('get_makes', {eventId}, response=> {
             setMakes(response.makes);
             setModels([]);
         });
     }

    const getModels = async (makeId) => {
        await runAction('get_models', {makeId}, response => {
            setModels(response.models);
        })
    }

    const saveMake = async (data, callback) => {
        const action = data.id ? 'update_make' : 'create_make';
        await runAction(action, {...data, eventId}, response => {
            getMakes();
            callback();
        })
    }

    const saveModel = async (data, callback) => {
        const action = data.id ? 'update_model' : 'create_model';
        await runAction(action, data, response => {
            getModels(data.makeId);
            callback();
        });
    }

    const deleteMakes = async (ids) => {
        await runAction('delete_makes', {ids}, response => {
            console.log('deleted ', response.deleted, ' make(s)');
            getMakes();
        })
    }

    const deleteModels = async (ids, makeId) => {
        await runAction('delete_models', {ids}, response => {
            console.log('deleted ', response.deleted, ' model(s)');
            getModels(makeId);
        })
    }

    const provider = {
        makes,
        models,
        getMakes,
        getModels,
        saveMake,
        saveModel,
        deleteMakes,
        deleteModels,
    }

    return <MakesAndModelsContext.Provider value={provider}>{children}</MakesAndModelsContext.Provider>
}

function useMakesAndModels() {
    const context = React.useContext(MakesAndModelsContext);
    if (!context) {
        throw new Error('useMakesAndModels must be used within a MakesAndModelsProvider');
    }
    return context;
}

export { MakesAndModelsProvider, useMakesAndModels }