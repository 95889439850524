import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const CampaignLogsContext = React.createContext(null);

function CampaignLogsProvider({campaignId, children}) {
    const { runAction } = useCore();
    const [loadingLogs, setLoadingLogs] = useState(true);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        loadLogs();
    }, [])

    const loadLogs = async () => {
        setLoadingLogs(true);
        await runAction('get_campaign_logs', {campaignId}, response => {
            setLogs(response.logs);
            setLoadingLogs(false);
        });
    }

    const provider = {
        loadingLogs, logs
    }

    return <CampaignLogsContext.Provider value={provider}>{children}</CampaignLogsContext.Provider>
}

function useCampaignLogs() {
    const context = React.useContext(CampaignLogsContext);
    if (!context) {
        throw new Error('useCampaignLogs must be used within a CampaignLogsProvider');
    }
    return context;
}

export { CampaignLogsProvider, useCampaignLogs }