import { CampaignLogsProvider, useCampaignLogs } from "contexts/campaignLogs";
import React from "react";
import { useLoaderData } from "react-router-dom";
import moment from "moment";
import './campaignLogs.scss'

export async function loader({params}) {
    return params;
}

function CampaignLogs() {
    const loaderData = useLoaderData();

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Campaign logs</div>
                </div>
            </div>
            <CampaignLogsProvider campaignId={loaderData.campaign}>
                <Logs />
            </CampaignLogsProvider>
        </div>
    )
}

function Logs() {
    const { loadingLogs, logs } = useCampaignLogs();
    return (
        <div className='campaign-logs'>
            {logs.length === 0 
            ? <div className='no-logs'>There are no logs for this campaign</div> 
            : logs.map(log => <div key={log.id} className='campaign-logged-instance'>
                <div className='log-title'>{`Campaign log for ${moment(log.created).format('ddd, MMM Do, YYYY')}`}</div>
                <div className='log-data'><pre>{log.log}</pre></div>
            </div>)}
        </div>
    )
}

export default CampaignLogs;