import Toggle from 'components/toggle';
import React, { useEffect, useState } from 'react';
import { publish } from 'pubsub-js';

function Option({option, onSave}) {
    const [type, setType] = useState(1);
    const [label, setLabel] = useState('');
    const [subLabel, setSubLabel] = useState('');
    const [required, setRequired] = useState(false);
    const [choices, setChoices] = useState('');

    useEffect(() => {
        if (option) {
            setType(option.type);
            setLabel(option.label);
            setSubLabel(option.subLabel);
            setRequired(option.required);
            //debugger
            if (option.choices && option.choices.length > 0) {
                if (typeof option.choices[0] === 'string') {
                    setChoices(option.choices.join(','));
                } else {
                    setChoices(option.choices.map(o => o.label).join(','));
                }
            }
        } else {
            setType(1);
            setLabel('');
            setSubLabel('');
            setRequired(false);
            setChoices('');
        }
    }, [option])

    const saveHandler = () => {
        if (label.length === 0) {
            publish('error', 'Label is a required field');
            return
        }
        const data = {
            type,
            label,
            subLabel,
            required,
            choices: choices.split(',').map(c => c.trim())
        }
        onSave(data);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Type</label>
                <select value={type} onChange={evt => setType(evt.target.value)}>
                    <option value={1}>Text</option>
                    <option value={2}>Choice</option>
                </select>
                <label>Label</label>
                <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength={64} />
                <label>subLabel</label>
                <input type='text' value={subLabel} onChange={e => setSubLabel(e.target.value)} maxLength={128} />
                <div style={{display: 'flex',alignItems:'center'}}>
                    <Toggle checked={required} onChange={newVal => setRequired(newVal)}/>Required
                </div>
                {
                    (type === '2' || type === 2) && (
                        <>
                            <label>Choices (comma separated list of choices)</label>
                            <textarea style={{height:'100px'}} value={choices} onChange={e => setChoices(e.target.value)} maxLength={512}
                                placeholder='Small, Medium, Large' />
                        </>
                    )
                }
            </div>
            <div className='buttonsHolder'>
				<button onClick={saveHandler}>Save</button>
			</div>
        </div>
    )
}

export default Option;