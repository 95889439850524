import React from 'react';
import './landing.scss';
import logo from '../../assets/logo-navy.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function Landing() {
    const navigate = useNavigate();

    return (
        <div className='landing-container'>
            <div className='landing-main'>
                <div className='landing-left'>
                        <img className='landing-logo' src={logo}/>
                        <div className='landing-title'>Admin Portal</div>
                        <div className='landing-description'>
                            The Middletwin Admin Portal is for car club and car show 
                            administrators to use to manage their clubs and shows.
                        </div>
                        <div className='landing-button'>
                            <button className='secondary' onClick={() => navigate('/signup')}>Create Trial Account</button>
                        </div>
                        <div className='landing-button'>
                            <button onClick={() => navigate('/login')}>Sign in</button>
                        </div>
                        <div className='landing-legal'>
                            <a href='http://www.middletwin.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
                            <a href='http://www.middletwin.com/terms-of-use/' target='_blank' rel='noopener noreferrer'>Terms of Use</a>
                        </div>
                        <div className='landing-support'>
                            Customer Support Contact<br/>
                            <a href='mailto="support@middletwin.com"'>support@middletwin.com</a>
                        </div>
                    <div className='landing-copy'>
                        &copy; {moment().format('YYYY')} Wade Girard, All Rights Reserved
                    </div>
                </div>
                <div className='landing-right'></div>
            </div>
        </div>
    )
}

export default Landing;