import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Products from './products';
import Categories from './categories';
import { faTag, faTshirt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export async function loader({params}) {
    return params;
}

function Store() {
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const [view, setView] = useState(null);
    
    useEffect(() => {
        if (loaderData) {
            setView(loaderData.view ? loaderData.view : null);
        }
    }, [loaderData])

    const getTitle = () => {
        return view === 'products' ? 'Products' : 'Categories';
    }

    return(
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Store {view && getTitle()}</div>
                </div>
            </div>
            {!view &&
                <div className='showLanding'>
                    <div className='showLandingTile' onClick={() => navigate('/admin/club/store/products')}>
                        <FontAwesomeIcon icon={faTshirt} size='6x'/>
                        <h6>Products</h6>
                    </div>
                    <div className='showLandingTile' onClick={() => navigate('/admin/club/store/categories')}>
                        <FontAwesomeIcon icon={faTag} size='6x'/>
                        <h6>Categories</h6>
                    </div>
                </div>
            }
            {view === 'products' &&
                <Products />
            }
            {view === 'categories' &&
                <Categories />
            }
        </div>
    )
}

export default Store;