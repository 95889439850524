import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailEditor from 'react-email-editor';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';

import { useAuth } from 'contexts/auth';
import { useEmailTemplates } from 'contexts/emailTemplates';
import { useEmailTemplate } from 'contexts/emailTemplate';

const triggerOptions = [
    { id: 2, label: 'New Files' },
    { id: 3, label: 'New Events' },
    { id: 4, label: 'New Products' },
    { id: 8, label: 'Member credit card expiring'},
    { id: 9, label: 'Member credit card expired'}
];

function TemplateDetails({onSave, onSend, onHide}) {
    const emailEditorRef = useRef(null);
    const { loadingTemplate, template } = useEmailTemplate();
    const { templates } = useEmailTemplates();
    const [isLoaded, setIsLoaded] = useState(false);
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [version, setVersion] = useState(2);
    const [templateDesign, setTemplateDesign] = useState('');
    const [settings, setSettings] = useState({subject: '', fromName: '', fromEmail: ''});
    const [trigger, setTrigger] = useState(0);
    const auth = useAuth();

    useEffect(() => {
        if (!loadingTemplate) {
            console.log(template.id);
            setId(template.id);
            setName(template.name);
            setVersion(template.version);
            setTemplateDesign(template.design);
            setSettings(JSON.parse(template.settings));
            setTrigger(template.trigger);
        }
    }, [loadingTemplate])

    useEffect(() => {
        if (isLoaded) {
            if (templateDesign.length > 0) {
                try {
                    let jsonDesign = JSON.parse(templateDesign);
                    if (emailEditorRef.current) {
                        emailEditorRef.current.editor.loadDesign(jsonDesign);
                    } else {
                        console.error('email editor (current) is null');
                    }
                } catch (error) {
                    console.error('JSON parsing error:' + error)
                }
            } else {
                console.error('missing template design')
            }
        } else {
            console.error('editor is not loaded')
        }
    }, [isLoaded])

    const send = () => {
        let targetEmail = prompt('Enter the email address you want to send the email to.', auth.user.email);

        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            let finalData = {
                id: id,
                name: name,
                version: version,
                template: html,
                design: JSON.stringify(design),
                trigger: trigger,
                settings: JSON.stringify(settings),
            };

            if (onSave) {
                onSave(finalData, 'send', () => {
                    let data = {
                        id: id,
                        email: targetEmail,
                    };
                    if (onSend) {
                        onSend(data);
                    }
                });
            }
        });
    };

    const saveDesign = () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            let finalData = {
                id: id,
                name: name,
                version: version,
                template: html,
                design: JSON.stringify(design),
                trigger: trigger,
                settings: JSON.stringify(settings),
            };

            if (onSave) {
                onSave(finalData, 'save');
            }
        });
    };

    const setSetting = (key, value) => {
        let obj = Object.assign({}, settings);
        obj[key] = value;
        setSettings(obj);
    };

    const triggerIsUsed = (triggerId) => {
        if (trigger === triggerId) {
            return false;
        }

        const foundTemplateUsingTrigger = templates.find(t => t.trigger == triggerId);
        return foundTemplateUsingTrigger != null;
    }

    return (
        <div className='contact-details'>
            <div className='contact-settings-panel'>
                <div
                    className='breadcrumb'
                    onClick={() => {
                        onHide();
                    }}>
                    <div className='breadcrumb-label'>
                        <FontAwesomeIcon icon={faAngleLeft} /> Templates
                    </div>
                </div>
                <div className='contact-name'>{name}</div>
                <br />
                <label>Name</label>
                <input type='text'
                    value={name}
                    onChange={(evt) => {
                        setName(evt.target.value);
                    }}
                />
                <label>Trigger this email for</label>
                <select
                    value={trigger}
                    onChange={(evt) => {
                        setTrigger(evt.target.value);
                    }}>
                    <option value='0'>Nothing</option>
                    {triggerOptions.map((trigger) => (
                        <option key={trigger.id} value={trigger.id} disabled={triggerIsUsed(trigger.id)}>{trigger.label}</option>
                    ))}
                </select>
                <label>Subject</label>
                <input type='text'
                    value={settings.subject}
                    onChange={(evt) => {
                        setSetting('subject', evt.target.value);
                    }}
                />
                <label>From Name</label>
                <input type='text'
                    value={settings.fromName}
                    onChange={(evt) => {
                        setSetting('fromName', evt.target.value);
                    }}
                />
                <label>From Email (Must be a validated email address)</label>
                <input type='text'
                    value={settings.fromEmail}
                    onChange={(evt) => {
                        setSetting('fromEmail', evt.target.value);
                    }}
                />
                <br />
                <div className='button-container'>
                    <button onClick={saveDesign}>Save</button>
                    <button className='secondary' onClick={send}>Send test</button>
                </div>
            </div>
            <div className='contact-communication-panel' style={{ width: '75%' }}>
                {!loadingTemplate &&
                    <EmailEditor ref={emailEditorRef} onLoad={() => setIsLoaded(true)} />
                }
            </div>
        </div>
    );
}

export default TemplateDetails;
