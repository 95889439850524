import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import 'style/mailingLabels.scss';
import 'style/print.scss';
import { useCore } from 'contexts/core';

export async function loader({params}) {
    return params;
}

function MailingLabels() {
    const { runAction } = useCore();
    const loaderData = useLoaderData();
    const [members, setMembers] = useState(null);
    const [vendors, setVendors] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        if (loaderData.type === 'vendors') {
            let data = {'tenantUUID': loaderData.tenant, 'event': loaderData.event};
            runAction('get_all_vendors', data, 
                (result) => {
                    if (result) {
                        setVendors(result.vendors);
                    }
                });
        } else {
            let data = {'tenantUUID': loaderData.tenant, 'type': loaderData.type, 'format': 'json'};
            runAction('get_all_members', data, 
                (result) => {
                    if (result) {
                        setMembers(result.members);
                    }
                });
        }
    }
    
    const renderMemberPages = () => {
        let pages = [];
        let start = 0, end = 30;
        while (start < members.length) {
            let pageMembers = members.slice(start, end);
            let labels = pageMembers.map((m , idx) => 
                    <div key={m.id} className='mailing-label'>
                        <div className='content'>
                            Member#:{m.id}<br/>
                            {m.name}<br/>
                            {m.address}<br/>
                            {m.city + ', ' + m.state + ' ' + m.zip}
                        </div>
                    </div>
                );
            pages.push(
                <div key={`page-${start}`} className='mailing-labels-page'>
                    <div className='mailing-labels-inner'>{labels}</div>
                    <div className='page-break'></div>
                </div>);
            start += 30;
            end += 30;
        }
        return pages;
    }

    const renderVendorPages = () => {
        let pages = [];
        let start = 0, end = 30;
        while (start < vendors.length) {
            let pageMembers = vendors.slice(start, end);
            let labels = pageMembers.map((p , idx) => 
                    <div key={idx} className='mailing-label'>
                        <div className='content'>
                            {p.name}<br/>
                            {p.address}<br/>
                            {p.address2 !== '' ? p.address2 : null}
                            {p.city + ', ' + p.state + ' ' + p.zip}
                        </div>
                    </div>
                );
            pages.push(<div className='mailing-labels-inner'>{labels}</div>)
            start += 30;
            end += 30;
        }
        return pages;
    }

    return(
        <div className='portrait-printable-area portrait'>
            {members ? renderMemberPages() : vendors ? renderVendorPages() : null}
        </div>
    )
}

export default MailingLabels;